import React, { createContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDeviceScreen from '../hooks/useDeviceScreen';

const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
    const deviceScreen = useDeviceScreen();
    const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

    const [appData, setAppData] = useState({
        theme: 'light',
    });

    const [fullScreenStatus, setFullScreenStatus] = useState(false);

    const [leftMenuStatus, setLeftMenuStatus] = useState(false);
    const [rightMenuStatus, setRightMenuStatus] = useState(false);
    const [asideStatus, setAsideStatus] = useState(
        deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE,
    );

    const [rightPanel, setRightPanel] = useState(false);

    useLayoutEffect(() => {
        if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
            setAsideStatus(true);
            setLeftMenuStatus(false);
            setRightMenuStatus(false);
        }
        return () => {
            setAsideStatus(false);
        };
    }, [deviceScreen.width]);

    return (
        <ThemeContext.Provider
            value={{
                mobileDesign,
                appData,
                setAppData,
                fullScreenStatus,
                setFullScreenStatus,
                asideStatus,
                setAsideStatus,
                leftMenuStatus,
                setLeftMenuStatus,
                rightMenuStatus,
                setRightMenuStatus,
                rightPanel,
                setRightPanel,
            }}>
            {children}
        </ThemeContext.Provider>
    );
};
ThemeContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ThemeContext;
