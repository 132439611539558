import { call, put } from 'redux-saga/effects';

import { getUserIdentityApiCall } from '../apiCalls/getUserIdentityApiCall';

import {
    getUserIdentitySuccessfully,
    getUserIdentityFailed,
} from '../actions/getUserIdentityAction';
import { ECONNABORTED, SERVER_ERROR } from '../constant';

export function* getUserIdentitySaga(token) {
    try {
        const res = yield call(getUserIdentityApiCall, token.payload);
        if (res.status === 200) {
            const user = res.data;
            if (user.token && user.token !== '') {
                yield put(getUserIdentitySuccessfully(res.data));
            } else {
                yield put(getUserIdentityFailed(SERVER_ERROR));
            }
        } else if (res?.code === ECONNABORTED) {
            yield put(getUserIdentityFailed(SERVER_ERROR));
        } else {
            // Time Out
            let errors = res?.response?.data;
            // Server is down or other reasons.
            if (!errors) {
                errors = SERVER_ERROR;
            }
            // const errors = res?.response?.data;
            yield put(getUserIdentityFailed(errors));
        }
    } catch (err) {
        yield put(getUserIdentityFailed(SERVER_ERROR));
    }
}
