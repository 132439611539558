const LANG = {
    VI: {
        text: 'Tiếng Việt',
        lng: 'vi',
        icon: 'CustomVietnam',
    },
    EN: {
        text: 'English',
        lng: 'en-US',
        icon: 'CustomUsa',
    },
};

export const getLangWithKey = (key) => {
    return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;

/** 
    DE: {
        text: 'Deutsche',
        lng: 'de-DE',
        icon: 'CustomGermany',
    },
    FR: {
        text: 'Français',
        lng: 'fr-FR',
        icon: 'CustomFrance',
    },
    TR: {
        text: 'Türkçe',
        lng: 'tr-TR',
        icon: 'CustomTurkey',
    },
*/
