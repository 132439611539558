import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';

const ChangingLanguageHeader = () => {
    const styledBtn = {
        color: 'light',
        hoverShadow: 'default',
        isLight: true,
        size: 'lg',
    };

    const { i18n, t } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        showNotification(
            <span className='d-flex align-items-center'>
                <Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
                <span>{`${t('Language changed to')} ${getLangWithKey(lng)?.text}`}</span>
            </span>,
            t('You updated the language of the site'),
        );
    };

    useLayoutEffect(() => {
        let lang = 'vi';
        if (i18n.language) {
            lang = i18n.language;
        }
        document.documentElement.setAttribute('lang', lang);
    });

    return (
        <div className='col-auto'>
            <Dropdown>
                <DropdownToggle hasIcon={false}>
                    <Button
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...styledBtn}
                        icon={getLangWithKey(i18n.language)?.icon || 'Language'}
                        aria-label='Change language'
                    />
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                    {Object.keys(LANG).map((i) => (
                        <DropdownItem>
                            <Button icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
                                {LANG[i].text}
                            </Button>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
ChangingLanguageHeader.propTypes = {};
ChangingLanguageHeader.defaultProps = {};

export default ChangingLanguageHeader;
