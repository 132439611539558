import {
    GET_USER_IDENTITY,
    GET_USER_IDENTITY_FAILED,
    GET_USER_IDENTITY_SUCCESSFULLY,
} from '../constant';

export const getUserIdentity = (token) => ({
    type: GET_USER_IDENTITY,
    payload: token,
});

export const getUserIdentityFailed = (errors) => ({
    type: GET_USER_IDENTITY_FAILED,
    payload: errors,
});

export const getUserIdentitySuccessfully = (user) => ({
    type: GET_USER_IDENTITY_SUCCESSFULLY,
    payload: user,
});
