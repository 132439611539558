import { LOG_OUT, LOG_OUT_FAILED, LOG_OUT_SUCCESSFULLY } from '../constant';

export const logOut = () => ({
    type: LOG_OUT,
});

export const logOutFailed = () => ({
    type: LOG_OUT_FAILED,
});

export const logOutSuccessfully = () => ({
    type: LOG_OUT_SUCCESSFULLY,
});
