import axios from 'axios';

import { HOST, GG_LOGIN_URI, TIME_OUT } from '../constant';

export function ggAttemptLoginApiCall(token) {
    return axios({
        method: 'post',
        timeout: TIME_OUT,
        url: `${HOST}${GG_LOGIN_URI}`,
        data: {
            token,
        },
    })
        .then((res) => res)
        .catch((err) => err);
}
