import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { homeMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';

const isMobile = ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

const SummaryHeader = () => {
    const deviceScreen = useDeviceScreen();
    return (
        isMobile ? (
            <Header>
            <HeaderLeft>
                <Navigation
                    menu={homeMenu}
                    id='summary-top-menu'
                    horizontal={deviceScreen?.width >= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE}
                />
            </HeaderLeft>
            <CommonHeaderRight />
        </Header>
        ) : <></>
    );
};

export default SummaryHeader;
