import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import USERS from '../../common/data/userDummyData';
import { innerProfilePath } from '../../menu';
import { setPrefixUrl } from '../../utils';

export const UserAvatar = ({ srcSet, src }) => {
    return (
        <div className='user-avatar'>
            <img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
        </div>
    );
};
UserAvatar.propTypes = {
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
    srcSet: null,
};

const ProtonXUser = () => {
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const { avatarUrl } = user;
    return (
        <div
            role='presentation'
            className='user'
            onClick={() => history.push(`${innerProfilePath.profile.path}`)}>
            <UserAvatar
                srcSet={setPrefixUrl(avatarUrl) || USERS.NOONE.srcSet}
                src={setPrefixUrl(avatarUrl) || USERS.NOONE.src}
            />
            <div className='user-info'>
                <div className='user-name'>{`${user.firstName || USERS.NOONE.name} ${
                    user.lastName || USERS.NOONE.surname
                }`}</div>
                <div className='user-sub-title'>{user.role || USERS.NOONE.position}</div>
            </div>
        </div>
    );
};

export default ProtonXUser;
