import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import {
    dashboardMenu,
    demoPages,
    layoutMenu,
    innerCoursePath,
    innerProfilePath,
    materialsMenu,
    mleMenu,
    labelingMenu,
    innerNewsFeedPath,
    featuresDemos,
    adminMenu,
    storageMenu,
    smartEditor,
    smartEditorHR,
    smartPost,
    smartBlog,
    contentMenu,
    subscriptionMenu,
} from '../../menu';
import Login from '../../pages/presentation/auth/Login';

const LANDING = {
    DASHBOARD: lazy(() => import('../../pages/dashboard/DashboardPage')),
    SUMMARY: lazy(() => import('../../pages/SummaryPage')),
};

const AUTH = {
    PAGE_404: lazy(() => import('../../pages/presentation/auth/Page404')),
    VERIFY_USERS: lazy(() => import('../../pages/presentation/auth/VerifyUser')),
};

const APP = {
    USER: {
        USER_PROFILE: lazy(() => import('../../pages/presentation/appointment/ProtonXUserPage')),
    },
    DEMOS: {
        SMART_EDITOR_DEMO: lazy(() =>
            import('../../pages/presentation/demo-pages/SmartEditorDemo'),
        ),
        SMART_EDITOR_HR: lazy(() => import('../../pages/presentation/demo-pages/SmartEditorHR')),
        SMART_POST: lazy(() => import('../../pages/presentation/demo-pages/SmartPost')),
        SMART_BLOG: lazy(() => import('../../pages/presentation/demo-pages/SmartBlog')),
    },
    CONTENT: {
        LIST: lazy(() => import('../../pages/presentation/contents/ContentListPage')),
    },
    ADMIN: {
        STUDIO_MANAGMENT: lazy(() => import('../../pages/presentation/admin/StudioManagementPage')),
        CONTENT_MANAGEMENT: lazy(() =>
            import('../../pages/presentation/admin/ContentManagementPage'),
        ),
    },
    SUBSCRIPTION: lazy(() => import('../../pages/presentation/contents/Subscription')),
};

const PAGE_LAYOUTS = {
    HEADER_SUBHEADER: lazy(() =>
        import('../../pages/presentation/page-layouts/HeaderAndSubheader'),
    ),
    HEADER: lazy(() => import('../../pages/presentation/page-layouts/OnlyHeader')),
    SUBHEADER: lazy(() => import('../../pages/presentation/page-layouts/OnlySubheader')),
    CONTENT: lazy(() => import('../../pages/presentation/page-layouts/OnlyContent')),
    BLANK: lazy(() => import('../../pages/presentation/page-layouts/Blank')),
    ASIDE: lazy(() => import('../../pages/presentation/aside-types/DefaultAsidePage')),
    MINIMIZE_ASIDE: lazy(() => import('../../pages/presentation/aside-types/MinimizeAsidePage')),
};

const ContentRoutes = () => {
    const location = useLocation();
    return (
        <Switch location={location}>
            <Route
                exact
                path={`${innerProfilePath.profile.path}`}
                component={APP.USER.USER_PROFILE}
            />
            {/* Landing */}
            <Route exact path={dashboardMenu.summary.path} component={LANDING.SUMMARY} />
            <Route exact path={subscriptionMenu.subscription.path} component={APP.SUBSCRIPTION} />
            {/* Auth */}
            <Route exact path={demoPages.page404.path} component={AUTH.PAGE_404} />
            <Route exact path={demoPages.login.path} component={Login} />
            <Route
                exact
                path={`${demoPages.verify.path}/:uid/confirm-tokens/:token`}
                component={AUTH.VERIFY_USERS}
            />
            <Route
                exact
                path={`${featuresDemos.smartEditor.path}`}
                component={APP.DEMOS.SMART_EDITOR_DEMO}
            />

            {/* ADMIN */}
            <Route
                exact
                path={`${adminMenu.studioManagement.path}`}
                component={APP?.ADMIN?.STUDIO_MANAGMENT}
            />
            <Route
                exact
                path={`${adminMenu?.contentManagement?.path}`}
                component={APP?.ADMIN?.CONTENT_MANAGEMENT}
            />

            <Route exact path={`${smartEditor.path}`} component={APP.DEMOS.SMART_EDITOR_DEMO} />
            <Route exact path={`${smartEditorHR.path}`} component={APP.DEMOS.SMART_EDITOR_HR} />
            <Route exact path={`${smartPost.path}`} component={APP.DEMOS.SMART_POST} />
            <Route exact path={`${smartBlog.path}`} component={APP.DEMOS.SMART_BLOG} />

            {/* Content */}
            <Route exact path={`${contentMenu.list.path}`} component={APP.CONTENT.LIST} />

            <Route path='/health' component={AUTH.PAGE_404} />
            <Route path='/404' component={AUTH.PAGE_404} />
        </Switch>
    );
};

export default ContentRoutes;
