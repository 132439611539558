import { call, put } from 'redux-saga/effects';

import { setTokenToLocalStorage } from '../utils';

import { TOKEN_KEY } from '../constant';

import { attemptRegisterApiCall, confirmRegisterApiCall } from '../apiCalls/attemptRegisterApiCall';

import {
    attemptRegisterSuccessfully,
    attemptRegisterFailed,
    confirmRegisterSuccessfully,
    confirmRegisterFailed,
} from '../actions/attemptRegisterAction';

export function* attemptRegisterSaga(action) {
    const user = action.payload;

    if (
        user.email ||
        user.password ||
        user.email !== '' ||
        user.password !== '' ||
        user.firstName ||
        user.firstName !== '' ||
        user.lastName ||
        user.lastName !== '' ||
        user.role ||
        user.role !== ''
    ) {
        try {
            const res = yield call(attemptRegisterApiCall, user);

            if (res.status === 200) {
                yield put(attemptRegisterSuccessfully(res.data));
            } else {
                // FIX_ME: add more link
                yield put(attemptRegisterFailed(res));
            }
        } catch (e) {
            // FIX_ME
            const errRes = e.response;
            let errors;
            // Error case for Connection Refuse or other unknown Errors
            if (!errRes) {
                errors = {
                    server: {
                        msg: 'SOME_THING_WENT_WRONG',
                    },
                };
            } else {
                errors = errRes.data.errors || {};
            }
            yield put(attemptRegisterFailed(errors));
        }
    } else {
        // FIX_ME
        yield put(attemptRegisterFailed());
    }
}

export function* confirmRegisterSaga(action) {
    const { emailToken } = action.payload;
    try {
        const res = yield call(confirmRegisterApiCall, emailToken);

        if (res.status === 200) {
            yield put(confirmRegisterSuccessfully(res.data));
            yield call(setTokenToLocalStorage(TOKEN_KEY, res.data.token));
        } else {
            yield put(confirmRegisterFailed());
        }
    } catch (e) {
        // FIX_ME
        const errRes = e.response;
        let errors;
        // Error case for Connection Refuse or other unknown Errors
        if (!errRes) {
            errors = {
                server: {
                    msg: 'SOME_THING_WENT_WRONG',
                },
            };
        } else {
            errors = errRes.data.errors || {};
        }
        yield put(confirmRegisterFailed(errors));
    }
}
