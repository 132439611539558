import axios from 'axios';
import { HOST, SIGNUP_URI } from '../constant';

export function attempSignUpApiCall(user) {
    return axios({
        method: 'post',
        url: `${HOST}${SIGNUP_URI}`,
        data: user,
    });
}
