// Page Information

export const PROTONX_AI_TF_BIG_DATA_PAGE = 'https://protonx.ai/tf-big-data';

export const GET_USER_IDENTITY = 'GET_USER_IDENTITY';
export const GET_USER_IDENTITY_FAILED = 'GET_USER_IDENTITY_FAILED';
export const GET_USER_IDENTITY_SUCCESSFULLY = 'GET_USER_IDENTITY_SUCCESSFULLY';
export const TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND';

// Login
export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const ATTEMPT_LOGIN_FAILED = 'ATTEMPT_LOGIN_FAILED';
export const ATTEMPT_LOGIN_SUCCESSFULLY = 'ATTEMPT_LOGIN_SUCCESSFULLY';

// GG Login
export const GG_ATTEMPT_LOGIN = 'GG_ATTEMPT_LOGIN';
export const GG_ATTEMPT_LOGIN_FAILED = 'GG_ATTEMPT_LOGIN_FAILED';
export const GG_ATTEMPT_LOGIN_SUCCESSFULLY = 'GG_ATTEMPT_LOGIN_SUCCESSFULLY';

// Register
export const ATTEMPT_REGISTER = 'ATTEMPT_REGISTER';
export const ATTEMPT_REGISTER_FAILED = 'ATTEMPT_REGISTER_FAILED';
export const ATTEMPT_REGISTER_SUCCESSFULLY = 'ATTEMPT_REGISTER_SUCCESSFULLY';

export const CONFIRM_REGISTER = 'CONFIRM_REGISTER';
export const CONFIRM_REGISTER_FAILED = 'CONFIRM_REGISTER_FAILED';
export const CONFIRM_REGISTER_SUCCESSFULLY = 'CONFIRM_REGISTER_SUCCESSFULLY';

// Logout
export const ATTEMPT_LOGOUT = 'ATTEMPT_LOGOUT';

// Requests

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_REQUEST_SUCCESSFULLY = 'CREATE_REQUEST_SUCCESSFULLY';
export const CREATE_REQUEST_FAILED = 'CREATE_REQUEST_FAILED';
export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUESTS_SUCCESSFULLY = 'GET_REQUESTS_SUCCESSFULLY';
export const GET_REQUESTS_FAILED = 'GET_REQUESTS_FAILED';

export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_REQUEST_SUCCESSFULLY = 'UPDATE_REQUEST_SUCCESSFULLY';
export const UPDATE_REQUEST_FAILED = 'UPDATE_REQUEST_FAILED';

// User

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESSFULLY = 'UPDATE_USER_SUCCESSFULLY';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

// Forgot Password

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESSFULLY = 'FORGOT_PASSWORD_SUCCESSFULLY';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

// Reset Password

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESSFULLY = 'RESET_PASSWORD_SUCCESSFULLY';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

// Token

export const TOKEN_KEY = 'userToken';
export const NULL_TOKEN = 'NULL_TOKEN';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';

export const getHOST = () => {
    return process.env.REACT_APP_STAGE === 'production'
        ? 'https://api.protonx.io'
        : 'https://api.protonx.io';
};

export const getSmartCookAPI = () => {
    return process.env.REACT_APP_STAGE === 'production'
        ? 'https://smartcook.bluestudio.ai/'
        : 'https://smartcook.bluestudio.ai/';
};

export const SMART_EDITOR_URL = 'https://smarte.protonx.io';
export const SMART_HR_URL = 'https://smartjdb.bluestudio.ai';

export const KEYWORD_URI = '/keywords';
export const OPENING_TEXT_URI = '/opening-texts';
export const GENERATED_SEQUENCE_URI = '/postgeneratedsequences';
export const CONTENT_URI = '/contents';

export const HOST = getHOST();
export const SMART_COOK_API_END_POINT = getSmartCookAPI();

// Login
export const IDENTITY_URI = '/identity';
export const LOGIN_URI = '/login';
export const GG_LOGIN_URI = '/gglogin';

// Version
export const VERSION_URI = '/version';

// Register
export const USERS_URI = '/users';
export const USERS_CONFIRM_URI = '/users/confirm';

export const FILE_UPLOAD_URI = '/file-upload';
export const PUBLIC_FILE_UPLOAD_URI = '/public-files';
export const PRIVATE_FILE_UPLOAD_URI = '/private-files';
export const FILES_URI = '/files';
export const REQUESTS_URI = '/requests';

// Forgot + Reset Password URI
export const FORGOT_PASSWORD_URI = '/forgot-password';
export const RESET_PASSWORD_URI = '/reset-password';

// Script Items
export const SCRIPT_ITEMS_URI = '/script-items';

// Topics
export const TOPICS_URI = '/topics';

export const PATCH_TOPIC_API = 'PATCH_TOPIC_API';
export const PATCH_TOPIC_API_FAILED = 'PATCH_TOPIC_API_FAILED';
export const PATCH_TOPIC_API_SUCCESSFULLY = 'PATCH_TOPIC_API_SUCCESSFULLY';
export const CREATE_TOPIC_API = 'CREATE_TOPIC_API';
export const CREATE_TOPIC_API_FAILED = 'CREATE_TOPIC_API_FAILED';
export const CREATE_TOPIC_API_SUCCESSFULLY = 'CREATE_TOPIC_API_SUCCESSFULLY';

export const DELETE_TOPIC_API = 'DELETE_TOPIC_API';
export const DELETE_TOPIC_API_FAILED = 'DELETE_TOPIC_API_FAILED';
export const DELETE_TOPIC_API_SUCCESSFULLY = 'DELETE_TOPIC_API_SUCCESSFULLY';

// CourswWork Materials
export const COURSE_WORK_MATERIALS_URI = '/cwmaterials';

export const CREATE_CW_MATERIAL_API = 'CREATE_CW_MATERIAL_API';
export const CREATE_CW_MATERIAL_API_FAILED = 'CREATE_CW_MATERIAL_API_FAILED';
export const CREATE_CW_MATERIAL_API_SUCCESSFULLY = 'CREATE_CW_MATERIAL_API_SUCCESSFULLY';

export const DELETE_CW_MATERIAL_API = 'DELETE_CW_MATERIAL_API';
export const DELETE_CW_MATERIAL_API_FAILED = 'DELETE_CW_MATERIAL_API_FAILED';
export const DELETE_CW_MATERIAL_API_SUCCESSFULLY = 'DELETE_CW_MATERIAL_API_SUCCESSFULLY';

export const COPY_MATERIAL_API = 'COPY_MATERIAL_API';
export const COPY_MATERIAL_API_FAILED = 'COPY_MATERIAL_API_FAILED';
export const COPY_MATERIAL_API_SUCCESSFULLY = 'COPY_MATERIAL_API_SUCCESSFULLY';

export const DELETE_MATERIAL_API = 'DELETE_MATERIAL_API';
export const DELETE_MATERIAL_API_FAILED = 'DELETE_MATERIAL_API_FAILED';
export const DELETE_MATERIAL_API_SUCCESSFULLY = 'DELETE_MATERIAL_API_SUCCESSFULLY';

// Course Work
export const COURSE_WORK_URI = '/cws';
export const COURSE_WORK = 'g';
export const CREATE_CW_API = 'CREATE_CW_API';
export const CREATE_CW_API_FAILED = 'CREATE_CW_API_FAILED';
export const CREATE_CW_API_SUCCESSFULLY = 'CREATE_CW_API_SUCCESSFULLY';

export const PATCH_CW_API = 'PATCH_CW_API';
export const PATCH_CW_API_FAILED = 'PATCH_CW_API_FAILED';
export const PATCH_CW_API_SUCCESSFULLY = 'PATCH_CW_API_SUCCESSFULLY';

export const DELETE_CW_API = 'DELETE_CW_API';
export const DELETE_CW_API_FAILED = 'DELETE_CW_API_FAILED';
export const DELETE_CW_API_SUCCESSFULLY = 'DELETE_CW_API_SUCCESSFULLY';

// Material
export const MARKDOWN_URI = '/markdowns';
export const CREATE_MARKDOWN_API = 'CREATE_MARKDOWN_API';
export const CREATE_MARKDOWN_API_FAILED = 'CREATE_MARKDOWN_API_FAILED';
export const CREATE_MARKDOWN_API_SUCCESSFULLY = 'CREATE_MARKDOWN_API_SUCCESSFULLY';
export const PATCH_MATERIAL_API = 'PATCH_MATERIAL_API';
export const PATCH_MULTILPLE_MATERIALS_API = 'PATCH_MULTILPLE_MATERIALS_API';
export const PATCH_MATERIAL_API_FAILED = 'PATCH_MATERIAL_API_FAILED';
export const PATCH_MATERIAL_API_SUCCESSFULLY = 'PATCH_MATERIAL_API_SUCCESSFULLY';

// Test Code
export const TESTCODE_URI = '/testcodes';
export const CREATE_TESTCODE_API = 'CREATE_TESTCODE_API';
export const CREATE_TESTCODE_API_FAILED = 'CREATE_TESTCODE_API_FAILED';
export const CREATE_TESTCODE_API_SUCCESSFULLY = 'CREATE_TESTCODE_API_SUCCESSFULLY';

export const MATERIALS_URI = '/materials';
export const LIST_MATERIALS_API = 'LIST_MATERIALS_API';
export const LIST_MATERIALS_API_FAILED = 'LIST_MATERIALS_API_FAILED';
export const LIST_MATERIALS_API_SUCCESSFULLY = 'LIST_MATERIALS_API_SUCCESSFULLY';

// Auto Grading
export const AUTO_GRADING_API = 'AUTO_GRADING_API';
export const AUTO_GRADING_API_FAILED = 'AUTO_GRADING_API_FAILED';
export const AUTO_GRADING_API_SUCCESSFULLY = 'AUTO_GRADING_API_SUCCESSFULLY';

// Upload sub
export const UPLOAD_SUB_FILE = 'UPLOAD_SUB_FILE';
export const UPLOAD_SUB_FILE_FAILED = 'UPLOAD_SUB_FILE_FAILED';
export const UPLOAD_SUB_FILE_SUCCESSFULLY = 'UPLOAD_SUB_FILE_SUCCESSFULLY';

// Clean assignment state.
export const CLEAN_ASSIGNMENT_STATE = 'CLEAN_ASSIGNMENT_STATE';
export const CLEAN_COURSE_STATE = 'CLEAN_COURSE_STATE';

// Clean material state
export const CLEAN_MATERIAL_STATE = 'CLEAN_MATERIAL_STATE';

// Markdown Modal
export const MARKDOWN_CREATE_MODE = 'MARKDOWN_CREATE_MODE';
export const MARKDOWN_EDIT_MODE = 'MARKDOWN_EDIT_MODE';

// Markdown Modal
export const TEST_CODE_CREATE_MODE = 'TEST_CODE_CREATE_MODE';
export const TEST_CODE_EDIT_MODE = 'TEST_CODE_EDIT_MODE';

// Topic
export const GET_TOPIC_API = 'GET_TOPIC_API';
export const GET_TOPIC_API_FAILED = 'GET_TOPIC_API_FAILED';
export const GET_TOPIC_API_SUCCESSFULLY = 'GET_TOPIC_API_SUCCESSFULLY';

// Topic Orders
export const TOPIC_ORDERS_URI = '/topicorders';
export const PATCH_TOPIC_ORDERS_API = 'PATCH_TOPIC_ORDERS_API';
export const PATCH_TOPIC_ORDERS_API_FAILED = 'PATCH_TOPIC_ORDERS_API_FAILED';
export const PATCH_TOPIC_ORDERS_API_SUCCESSFULLY = 'PATCH_TOPIC_ORDERS_API_SUCCESSFULLY';

// Topic CWM Orders
export const CW_MATERIAL_ORDERS_URI = '/cwmorders';
export const PATCH_CW_MATERIAL_ORDERS_API = 'PATCH_CW_MATERIAL_ORDERS_API';
export const PATCH_CW_MATERIAL_ORDERS_API_FAILED = 'PATCH_CW_MATERIAL_ORDERS_API_FAILED';
export const PATCH_CW_MATERIAL_ORDERS_API_SUCCESSFULLY =
    'PATCH_CW_MATERIAL_ORDERS_API_SUCCESSFULLY';

// Course Mode
export const COURSE_CREATE_MODE = 'COURSE_CREATE_MODE';
export const COURSE_EDIT_MODE = 'COURSE_EDIT_MODE';

// Topic Mode
export const INACTIVE_MODE = 'INACTIVE_MODE';
export const TOPIC_CREATE_MODE = 'TOPIC_CREATE_MODE';
export const TOPIC_EDIT_MODE = 'TOPIC_EDIT_MODE';
export const TOPIC_DELETE_MODE = 'TOPIC_DELETE_MODE';
export const TOPIC_DELETE_MESSAGE = 'All materials will still be stored in the database.';

// Student Mode

export const STUDENT_ADD_MODE = 'STUDENT_ADD_MODE';
export const STUDENT_REMOVE_MODE = 'STUDENT_REMOVE_MODE';

// Teacher Mode

export const TEACHER_ADD_MODE = 'TEACHER_ADD_MODE';
export const TEACHER_REMOVE_MODE = 'TEACHER_REMOVE_MODE';

// Course Work Material Mode
export const CW_MATERIAL_CREATE_MODE = 'CW_MATERIAL_CREATE_MODE';
export const CW_MATERIAL_EDIT_MODE = 'CW_MATERIAL_EDIT_MODE';
export const CW_MATERIAL_DELETE_MODE = 'CW_MATERIAL_DELETE_MODE';

// Course
export const COURSE_URI = '/courses';
export const STATS_URI = '/stats';

export const GET_COURSE = 'GET_COURSE';
export const GET_COURSE_INTERVAL = 'GET_COURSE_INTERVAL';
export const GET_COURSE_FAILED = 'GET_COURSE_FAILED';
export const GET_COURSE_SUCCESSFULLY = 'GET_COURSE_SUCCESSFULLY';
export const COURSE = 'COURSE';

// Course Info

export const GET_COURSE_INFO = 'GET_COURSE_INFO';
export const GET_COURSE_INFO_FAILED = 'GET_COURSE_INFO_FAILED';
export const GET_COURSE_INFO_SUCCESSFULLY = 'GET_COURSE_INFO_SUCCESSFULLY';

export const CREATE_COURSE_API = 'CREATE_COURSE_API';
export const CREATE_COURSE_API_FAILED = 'CREATE_COURSE_API_FAILED';
export const CREATE_COURSE_API_SUCCESSFULLY = 'CREATE_COURSE_API_SUCCESSFULLY';
export const PATCH_COURSE_API = 'PATCH_COURSE_API';
export const PATCH_COURSE_API_FAILED = 'PATCH_COURSE_API_FAILED';
export const PATCH_COURSE_API_SUCCESSFULLY = 'PATCH_COURSE_API_SUCCESSFULLY';
export const UPDATE_USER_COURSES_AFTER_PATCH_COURSE = 'UPDATE_USER_COURSES_AFTER_PATCH_COURSE';
export const DELETE_COURSE_API = 'DELETE_COURSE_API';
export const DELETE_COURSE_API_FAILED = 'DELETE_COURSE_API_FAILED';
export const DELETE_COURSE_API_SUCCESSFULLY = 'DELETE_COURSE_API_SUCCESSFULLY';
export const UPDATE_USER_COURSES_AFTER_DELETE_COURSE = 'UPDATE_USER_COURSES_AFTER_DELETE_COURSE';

// Course Attachment
export const ATTACHMENT_URI = '/attachments';

// Got it Attachment Status
export const GET_GOTIT_ATTACHMENT_STATUS = 'GET_GOTIT_ATTACHMENT_STATUS';
export const GET_GOTIT_ATTACHMENT_STATUS_FAILED = 'GET_GOTIT_ATTACHMENT_STATUS_FAILED';
export const GET_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY = 'GET_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY';
export const LIST_GOTIT_ATTACHMENT_STATUS = 'LIST_GOTIT_ATTACHMENT_STATUS';
export const LIST_GOTIT_ATTACHMENT_STATUS_FAILED = 'LIST_GOTIT_ATTACHMENT_STATUS_FAILED';
export const LIST_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY =
    'LIST_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY';

export const PATCH_GOTIT_ATTACHMENT_STATUS = 'PATCH_GOTIT_ATTACHMENT_STATUS';
export const PATCH_GOTIT_ATTACHMENT_STATUS_FAILED = 'PATCH_GOTIT_ATTACHMENT_STATUS_FAILED';
export const PATCH_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY =
    'PATCH_GOTIT_ATTACHMENT_STATUS_SUCCESSFULLY';

export const TOPIC = 'TOPIC';
export const LIST_GOTIT_TOPIC_STATUS = 'LIST_GOTIT_TOPIC_STATUS';
export const LIST_GOTIT_TOPIC_STATUS_FAILED = 'LIST_GOTIT_TOPIC_STATUS_FAILED';
export const LIST_GOTIT_TOPIC_STATUS_SUCCESSFULLY = 'LIST_GOTIT_TOPIC_STATUS_SUCCESSFULLY';

// Got it Topic Status

export const PATCH_GOTIT_TOPIC_STATUS = 'PATCH_GOTIT_TOPIC_STATUS';
export const PATCH_GOTIT_TOPIC_STATUS_FAILED = 'PATCH_GOTIT_TOPIC_STATUS_FAILED';
export const PATCH_GOTIT_TOPIC_STATUS_SUCCESSFULLY = 'PATCH_GOTIT_TOPIC_STATUS_SUCCESSFULLY';

// Join Request
export const JOIN_REQUEST_URI = '/join';
export const CREATE_JOIN_REQUEST = 'CREATE_JOIN_REQUEST';
export const CREATE_JOIN_REQUEST_FAILED = 'CREATE_JOIN_REQUEST_FAILED';
export const CREATE_JOIN_REQUEST_SUCCESSFULLY = 'CREATE_JOIN_REQUEST_SUCCESSFULLY';

export const GET_JOIN_REQUEST = 'GET_JOIN_REQUEST';
export const GET_JOIN_REQUEST_FAILED = 'GET_JOIN_REQUEST_FAILED';
export const GET_JOIN_REQUEST_SUCCESSFULLY = 'GET_JOIN_REQUEST_SUCCESSFULLY';

export const PATCH_JOIN_REQUEST = 'PATCH_JOIN_REQUEST';
export const PATCH_JOIN_REQUEST_FAILED = 'PATCH_JOIN_REQUEST_FAILED';
export const PATCH_JOIN_REQUEST_SUCCESSFULLY = 'PATCH_JOIN_REQUEST_SUCCESSFULLY';

// Student Submissions

export const LIST_STUDENT_SUBMISSIONS = 'LIST_STUDENT_SUBMISSIONS';
export const LIST_STUDENT_SUBMISSIONS_FAILED = 'LIST_STUDENT_SUBMISSIONS_FAILED';
export const LIST_STUDENT_SUBMISSIONS_SUCCESSFULLY = 'LIST_STUDENT_SUBMISSIONS_SUCCESSFULLY';

export const LIST_SUBS_FOR_STUDENT_VIEW = 'LIST_SUBS_FOR_STUDENT_VIEW';
export const LIST_SUBS_FOR_STUDENT_VIEW_FAILED = 'LIST_SUBS_FOR_STUDENT_VIEW_FAILED';
export const LIST_SUBS_FOR_STUDENT_VIEW_SUCCESSFULLY = 'LIST_SUBS_FOR_STUDENT_VIEW_SUCCESSFULLY';

// List Student Grades
export const LIST_STUDENT_GRADES = 'LIST_STUDENT_GRADES';
export const LIST_STUDENT_GRADES_FAILED = 'LIST_STUDENT_GRADES_FAILED';
export const LIST_STUDENT_GRADES_SUCCESSFULLY = 'LIST_STUDENT_GRADES_SUCCESSFULLY';

export const CREATED = 'CREATED';
export const PENDING = 'PENDING';
export const DONE = 'DONE';
export const APPROVED = 'APPROVED';

// Loading Status
export const INIT = 'INIT';
export const SUCCESSFUL = 'SUCCESSFUL';
export const FAILED = 'FAILED';

export const STUDENT_SUBMISSION = 'StudentSubmission';

export const SUBMISSION_STATE_UNSPECIFIED = 'SUBMISSION_STATE_UNSPECIFIED';
export const NEW = 'NEW';
export const TURNED_IN = 'TURNED_IN';
export const RETURNED = 'RETURNED';
export const RECLAIMED_BY_STUDENT = 'RECLAIMED_BY_STUDENT';
export const DRAFT_GRADE_POINTS_EARNED_CHANGE = 'DRAFT_GRADE_POINTS_EARNED_CHANGE';

export const PATCH_STUDENT_SUBMISSION = 'PATCH_STUDENT_SUBMISSION';
export const PATCH_STUDENT_SUBMISSION_API = 'PATCH_STUDENT_SUBMISSION_API';
export const PATCH_STUDENT_SUBMISSION_API_FAILED = 'PATCH_STUDENT_SUBMISSION_API_FAILED';
export const PATCH_STUDENT_SUBMISSION_API_SUCCESSFULLY =
    'PATCH_STUDENT_SUBMISSION_API_SUCCESSFULLY';

export const GENERATE_STUDENT_SUBMISSION_MATERIALS_API =
    'GENERATE_STUDENT_SUBMISSION_MATERIALS_API';
export const GENERATE_STUDENT_SUBMISSION_MATERIALS_API_FAILED =
    'GENERATE_STUDENT_SUBMISSION_MATERIALS_API_FAILED';
export const GENERATE_STUDENT_SUBMISSION_MATERIALS_API_SUCCESSFULLY =
    'GENERATE_STUDENT_SUBMISSION_MATERIALS_API_SUCCESSFULLY';

export const GET_STUDENT_SUBMISSION_API = 'GET_STUDENT_SUBMISSION_API';
export const GET_STUDENT_SUBMISSION_API_FAILED = 'GET_STUDENT_SUBMISSION_API_FAILED';
export const GET_STUDENT_SUBMISSION_API_SUCCESSFULLY = 'GET_STUDENT_SUBMISSION_API_SUCCESSFULLY';

// Grade Details

export const GET_GRADE_DETAILS_API = 'GET_GRADE_DETAILS_API';
export const GET_GRADE_DETAILS_API_FAILED = 'GET_GRADE_DETAILS_API_FAILED';
export const GET_GRADE_DETAILS_API_SUCCESSFULLY = 'GET_GRADE_DETAILS_API_SUCCESSFULLY';
export const RESET_LOADING_AUTO_GRADING_TIMES = 'RESET_LOADING_AUTO_GRADING_TIMES';

// Assignment
export const ASSIGNMENT_URI = '/assignments';
export const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const GET_ASSIGNMENT_FAILED = 'GET_ASSIGNMENT_FAILED';
export const GET_ASSIGNMENT_SUCCESSFULLY = 'GET_ASSIGNMENT_SUCCESSFULLY';
export const ASSIGNMENT = 'ASSIGNMENT';

// Assignment Solution
export const GET_ASSIGNMENT_SOLUTION = 'GET_ASSIGNMENT_SOLUTION';
export const GET_ASSIGNMENT_SOLUTION_FAILED = 'GET_ASSIGNMENT_SOLUTION_FAILED';
export const GET_ASSIGNMENT_SOLUTION_SUCCESSFULLY = 'GET_ASSIGNMENT_SOLUTION_SUCCESSFULLY';

export const PATCH_COURSE_MATERIAL_API = 'PATCH_COURSE_MATERIAL_API';
export const PATCH_COURSE_MATERIAL_API_FAILED = 'PATCH_COURSE_MATERIAL_API_FAILED';
export const PATCH_COURSE_MATERIAL_API_SUCCESSFULLY = 'PATCH_COURSE_MATERIAL_API_SUCCESSFULLY';
export const NO_TITLE = 'NO_TITLE';

export const SET_ACTIVE_SUBMISSION = 'SET_ACTIVE_SUBMISSION';

// Error
export const EMAIL_IS_IN_WRONG_FORMAT = 'Email is in wrong format';
export const EMAIL_IS_REQUIRED = 'Email is required';
export const PASSWORD_DOES_NOT_SATISFY_REQUIREMENT =
    'Password must be 8-16 characters, contain at least 1 uppercase letter, 1 special character !,@,#,$,%,etc., and 1 number [0-9]';
export const PASSWORD_DOES_NOT_MATCH = 'Confirmed password does not match';
export const PASSWORD_LENGTH_MUST_BE_MORE_THAN_8 = 'Password length must be more than 8';
export const PASSWORD_CONTAIN_A_SPECIAL_CHARACTER =
    'Password should contain 1 special character !,@,#,$,%';
export const PASSWORD_CONTAIN_A_LOWERCASE_CHARACTER =
    'Password should contain 1 lowercase character';
export const PASSWORD_CONTAIN_A_UPPERCASE_CHARACTER =
    'Password should contain 1 uppercase character';
export const PASSWORD_CONTAIN_A_NUMBER = 'Password should contain a number';
export const ROLE_DOES_NOT_SELECTED = 'Role is not selected';
export const ERRORS = {
    email: {
        USER_DOES_NOT_EXIST: 'User does not exist',
        USER_EXISTS_ALREADY: 'User already exists',
        EMAIL_IS_NOT_CONFIRMED: 'Email is not confirmed',
        EMAIL_IS_IN_WRONG_FORMAT: 'Email is in wrong format',
    },
    password: {
        WRONG_PASSWORD: 'Wrong password',
        TOKEN_IS_EXPIRED: 'Token is expired or has been used',
        PASSWORD_LENGTH_MUST_BE_MORE_THAN_8: 'Password length must be more than 8',
        PASSWORD_MUST_CONTAIN_ONE_SPECIAL_CHARACTER:
            'Password must contain 1 special character !,@,#,$,%',
    },
    server: {
        SOME_THING_WENT_WRONG: 'Something went wrong',
    },
};

export const UNKNOWN_ERROR = 'Unknown Error';

export const IMAGE_TYPES = ['image/jpeg', 'image/gif', 'image/png'];
export const PDF_TYPE = 'application/pdf';
export const IMAGE_MODE = 'img';
export const PDF_MODE = 'pdf';

export const PATIENT_ROLE = 'patient';
export const DOCTOR_ROLE = 'doctor';

export const CODE = 'code';
export const SOME_THING_WENT_WRONG = 'SOME_THING_WENT_WRONG';
export const SERVER_ERROR = {
    code: 500,
    errors: {
        server: {
            msg: 'SOME_THING_WENT_WRONG',
            readableMsg: 'Some thing went wrong',
        },
    },
};

export const ECONNABORTED = 'ECONNABORTED';
export const UN_GOTIT = 'UN_GOTIT';
export const GOTIT = 'GOTIT';
// Style:
export const INPUT_BORDER_ERROR_COLOR = 'red';

export const NA = 'N/A';
export const NO_CONTENT = 'No content';

export const Materials = 'Materials';
export const Assignments = 'Assignments';

export const DRIVEFILE = 'driveFile';
export const MARKDOWN = 'MARKDOWN';
export const TEST_CODE = 'TEST_CODE';
export const UNKNOWN_TYPE = 'unknownType';
export const YOUTUBE_VIDEO = 'youtubeVideo';
export const CODE_CHALLENGE = 'CodeChallenge';

export const DRIVE_FILE_BASE_URL = 'https://drive.google.com/file/d/';
export const USER_GUIDE_STATUSES_URI = '/user-guide-statuses';
export const APPLICATION_GUIDES_URI = '/application-guides';
export const APPLICATION_GUIDE_STEPS_URI = '/application-guide-steps';

export const STUDENT = 'student';
export const TEACHER = 'teacher';
export const USER = 'user';
export const ADMIN_ROLE = 'Admin';

export const STUDENTS = 'students';
export const TEACHERS = 'teachers';

export const LOADING = 'Loading...';
export const COURSE_WORK_MATERIALS_HAS_BEEN_UPDATED_SUCCESSFULLY =
    'Course Work Material has been updated successfully';
export const CourseWorkState = [
    {
        value: 'DRAFT',
        text: 'Draft',
    },
    {
        value: 'PUBLISHED',
        text: 'Published',
    },
];

export const ExtractCodeModeState = [
    {
        value: 'FUNCTION_ONLY',
        text: 'Function Only',
    },
    {
        value: 'ALL_CODE',
        text: 'All Code',
    },
];

export const LabelingProjectTemplateNotebookState = [
    {
        value: 'DRAFT',
        text: 'Draft',
    },
    {
        value: 'PUBLISHED',
        text: 'Published',
    },
];

export const LabelingProjectPricePlanState = [
    {
        value: 'DRAFT',
        text: 'Draft',
    },
    {
        value: 'PUBLISHED',
        text: 'Published',
    },
];

export const GuideState = [
    {
        value: 'DRAFT',
        text: 'Draft',
    },
    {
        value: 'PUBLISHED',
        text: 'Published',
    },
];

export const UserTypeState = [
    {
        value: 'User',
        text: 'User',
    },
    {
        value: 'PremiumUser',
        text: 'Premium User',
    },
];

export const DISPLAY_ALL_TOPICS = 'DISPLAY_ALL_TOPICS';
export const NEXT_TOPIC_WHEN_GOT_PRE_TOPIC = 'NEXT_TOPIC_WHEN_GOT_PRE_TOPIC';
export const COURSE_MODE_UNSPECIFIED = 'COURSE_MODE_UNSPECIFIED';

export const CourseMode = [
    {
        value: 'DISPLAY_ALL_TOPICS',
        text: 'Display all topics',
    },
    {
        value: 'NEXT_TOPIC_WHEN_GOT_PRE_TOPIC',
        text: 'Next topic when got previous topic',
    },
];
export const readableCourseMode = {
    DISPLAY_ALL_TOPICS: 'Display all topics',
    NEXT_TOPIC_WHEN_GOT_PRE_TOPIC: 'Next topic when got previous topic',
    COURSE_MODE_UNSPECIFIED: 'Course mode unspecified',
};

export const readableJoinRequestStatus = {
    CREATED: 'Created',
    PENDING: 'Pending',
    APPROVED: 'Approved',
    STATUS_UNSPECIFIED: 'Status unspecified',
};

export const MaterialState = [
    {
        value: 'DRAFT',
        text: 'Draft',
    },
    {
        value: 'PUBLISHED',
        text: 'Published',
    },
];

export const SubmissionModificationMode = [
    {
        value: 'MODIFIABLE_UNTIL_TURNED_IN',
        text: 'Modifiable until turned in',
    },
    {
        value: 'MODIFIABLE',
        text: 'Modifiable',
    },
];

export const ShareMode = [
    {
        value: 'VIEW',
        text: 'Students can view the shared file.',
    },
    {
        value: 'EDIT',
        text: 'Students can edit the shared file.',
    },
    {
        value: 'STUDENT_COPY',
        text: 'Students have a personal copy of the shared file.',
    },
];

export const STUDENT_COPY = 'STUDENT_COPY';
export const VIEW = 'VIEW';
export const EDIT = 'EDIT';
export const CREATE = 'CREATE';
export const SHARE_MODE = 'shareMode';
/**
{
    value: 'COURSE_WORK_STATE_UNSPECIFIED',
    text: 'Course work state unspecified',
},
{
    value: 'DELETED',
    text: 'Deleted',
}, 
    
*/

export const DRAFT = 'DRAFT';
export const PUBLISHED = 'PUBLISHED';

export const readableSubmissionState = {
    SUBMISSION_STATE_UNSPECIFIED: 'Unspecified State',
    STATE_UNSPECIFIED: 'Unspecified State',
    NEW: 'New Submission',
    CREATED: 'Submission created',
    TURNED_IN: 'Submission submitted',
    RETURNED: 'Submission returned',
    RECLAIMED_BY_STUDENT: 'Submission reclaimed',
    COURSE_WORK_TYPE_UNSPECIFIED: 'Unspecified course work type',
    UNKNOWN_GRADE_CHANGE_TYPE: 'Unknown',
    UNKNOWN: 'Unknown',
    DRAFT_GRADE_POINTS_EARNED_CHANGE: 'Draft Grade Updated',
    ASSIGNED_GRADE_POINTS_EARNED_CHANGE: 'Grade Updated',
    MAX_POINTS_CHANGE: 'Max Points Updated',
    STUDENT_EDITED_AFTER_TURN_IN: 'Student edited after turn in',
    '-': '-',
};

export const DEFAULT_COURSE_IMAGE =
    'https://storage.googleapis.com/protonx-cloud-storage/protonx-mle/course-icon.png';

export const VICTORY_CUP =
    'https://storage.googleapis.com/protonx-cloud-storage/protonx-mle/top1.gif';
export const PERSEVERANCE_BADGE =
    'https://storage.googleapis.com/protonx-cloud-storage/protonx-mle/top2.gif';
export const PARTICIPATING_BADGE =
    'https://storage.googleapis.com/protonx-cloud-storage/protonx-mle/top3.gif';

// Add student to course
export const ADD_STUDENT_TO_COURSE_API = 'ADD_STUDENT_TO_COURSE_API';
export const ADD_STUDENT_TO_COURSE_API_FAILED = 'ADD_STUDENT_TO_COURSE_API_FAILED';
export const REMOVE_STUDENT_FROM_COURSE_API_FAILED = 'REMOVE_STUDENT_FROM_COURSE_API_FAILED';
export const ADD_STUDENT_TO_COURSE_API_404 = 'ADD_STUDENT_TO_COURSE_API_404';
export const ADD_STUDENT_TO_COURSE_API_409 = 'ADD_STUDENT_TO_COURSE_API_409';
export const ADD_STUDENT_TO_COURSE_API_SUCCESSFULLY = 'ADD_STUDENT_TO_COURSE_API_SUCCESSFULLY';
export const ADD_STUDENT_TO_COURSE_NO_ERROR = 'ADD_STUDENT_TO_COURSE_NO_ERROR';
// Add teacher to course
export const ADD_TEACHER_TO_COURSE_API = 'ADD_TEACHER_TO_COURSE_API';
export const ADD_TEACHER_TO_COURSE_API_FAILED = 'ADD_TEACHER_TO_COURSE_API_FAILED';
export const ADD_TEACHER_TO_COURSE_API_404 = 'ADD_TEACHER_TO_COURSE_API_404';
export const ADD_TEACHER_TO_COURSE_API_409 = 'ADD_TEACHER_TO_COURSE_API_409';
export const ADD_TEACHER_TO_COURSE_API_SUCCESSFULLY = 'ADD_TEACHER_TO_COURSE_API_SUCCESSFULLY';
export const ADD_TEACHER_TO_COURSE_NO_ERROR = 'ADD_TEACHER_TO_COURSE_NO_ERROR';
export const REMOVE_TEACHER_FROM_COURSE_API_FAILED = 'REMOVE_TEACHER_FROM_COURSE_API_FAILED';

export const readableLoginError = {
    ATTEMPT_LOGIN_FAILED: 'Login Failed',
};

export const readableEditStudentError = {
    ADD_STUDENT_TO_COURSE_API_409: 'User was added already',
    ADD_STUDENT_TO_COURSE_API_404: 'User not found',
    ADD_STUDENT_TO_COURSE_API_FAILED: 'Add student failed',
    REMOVE_STUDENT_FROM_COURSE_API_FAILED: 'Remove student from course failed',
};

export const readableEditTeacherError = {
    ADD_TEACHER_TO_COURSE_API_409: 'User was added already',
    ADD_TEACHER_TO_COURSE_API_404: 'User not found',
    ADD_TEACHER_TO_COURSE_API_FAILED: 'Add teacher to course failed',
    REMOVE_TEACHER_FROM_COURSE_API_FAILED: 'Remove teacher from course failed',
};

export const readableEditCourseError = {
    CREATE_COURSE_API_FAILED: 'Create course failed',
    PATCH_COURSE_API_FAILED: 'Update course failed',
    DELETE_COURSE_API_FAILED: 'Delete course failed',
};

export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';
export const TOKEN_IS_EXPIRED = 'TOKEN_IS_EXPIRED';
export const SERVER_IS_UNDER_MAINTENANCE = 'SERVER_IS_UNDER_MAINTENANCE';
export const readableCommonError = {
    UPLOAD_IMAGE_FAILED: 'Upload image failed',
    TOKEN_IS_EXPIRED: 'Token is expired',
    SOME_THING_WENT_WRONG: 'Something went wrong',
    SERVER_IS_UNDER_MAINTENANCE: 'Server is unter maintenanance',
};

export const TIME_OUT = 1000 * 30;

// Logout
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_FAILED = 'LOG_OUT_FAILED';
export const LOG_OUT_SUCCESSFULLY = 'LOG_OUT_SUCCESSFULLY';
// Drive Constant
export const DRIVE_USER = 'user';
export const DRIVE_READER = 'reader';
export const DRIVE_WRITER = 'writer';

export const ADMIN_MAIL = 'protonx-mld-drive@protonx-mle-login.iam.gserviceaccount.com';

export const STUDENT_LIST = 'STUDENT_LIST';
export const JOIN_REQUEST_LIST = 'JOIN_REQUEST_LIST';

// Template Model
export const AVAILABLE = 'AVAILABLE';
export const readableTemplateMode = {
    AVAILABLE: 'Available',
    PENDING: 'Pending',
    NOT_AVAILABLE: 'Not Available',
};

export const readableGradeFileStatus = {
    PENDING: 'Pending',
    DONE: 'Done',
    SUCCESSFUL: 'Successful',
    CREATED: 'Created',
    '': '',
};

export const REQUEST_TRIAL_LINK = 'https://forms.gle/PtMffuEvqwGKkD968';

// Labeling Project
export const CREATE_LABELING_PROJECT_MODE = 'CREATE_LABELING_PROJECT_MODE';
export const EDIT_LABELING_PROJECT_MODE = 'EDIT_LABELING_PROJECT_MODE';
export const EDIT_LABELING_PROJECT = 'EDIT_LABELING_PROJECT';

// Create Project
export const LABELING_PROJECTS_URI = '/labeling-projects';
export const CREATE_LABELING_PROJECT_API = 'CREATE_LABELING_PROJECT_API';
export const CREATE_LABELING_PROJECT_API_FAILED = 'CREATE_LABELING_PROJECT_API_FAILED';
export const CREATE_LABELING_PROJECT_API_SUCCESSFULLY = 'CREATE_LABELING_PROJECT_API_SUCCESSFULLY';
export const VOICE_COMMAND_LABELING = 'VOICE_COMMAND_LABELING';

export const ProjectLabelingType = [
    {
        value: 'VOICE_COMMAND_LABELING',
        text: 'Voice Commands',
    },
];

// Label
export const LABELS_URI = '/labels';
export const VOICE_COMMAND_LABEL = 'VOICE_COMMAND_LABEL';

export const LIST_LABELING_PROJECT_API = 'LIST_LABELING_PROJECT_API';
export const LIST_LABELING_PROJECT_API_FAILED = 'LIST_LABELING_PROJECT_API_FAILED';
export const LIST_LABELING_PROJECT_API_SUCCESSFULLY = 'LIST_LABELING_PROJECT_API_SUCCESSFULLY';

export const PATCH_LABELING_PROJECT_API = 'PATCH_LABELING_PROJECT_API';
export const PATCH_LABELING_PROJECT_API_FAILED = 'PATCH_LABELING_PROJECT_API_FAILED';
export const PATCH_LABELING_PROJECT_API_SUCCESSFULLY = 'PATCH_LABELING_PROJECT_API_SUCCESSFULLY';

export const DELETE_LABELING_PROJECT_API = 'DELETE_LABELING_PROJECT_API';
export const DELETE_LABELING_PROJECT_API_FAILED = 'DELETE_LABELING_PROJECT_API_FAILED';
export const DELETE_LABELING_PROJECT_API_SUCCESSFULLY = 'DELETE_LABELING_PROJECT_API_SUCCESSFULLY';

export const GET_LABELING_PROJECT_API = 'GET_LABELING_PROJECT_API';
export const GET_LABELING_PROJECT_API_FAILED = 'GET_LABELING_PROJECT_API_FAILED';
export const GET_LABELING_PROJECT_API_SUCCESSFULLY = 'GET_LABELING_PROJECT_API_SUCCESSFULLY';

// Pricing Plan
export const COURSE_PRICING_PLANS_URI = '/course-pricing-plans';
export const CREATE_PRICING_PLAN_API = 'CREATE_PRICING_PLAN_API';
export const CREATE_PRICING_PLAN_API_FAILED = 'CREATE_PRICING_PLAN_API_FAILED';
export const CREATE_PRICING_PLAN_API_SUCCESSFULLY = 'CREATE_PRICING_PLAN_API_SUCCESSFULLY';

export const PATCH_PRICING_PLAN_API = 'PATCH_PRICING_PLAN_API';
export const PATCH_PRICING_PLAN_API_FAILED = 'PATCH_PRICING_PLAN_API_FAILED';
export const PATCH_PRICING_PLAN_API_SUCCESSFULLY = 'PATCH_PRICING_PLAN_API_SUCCESSFULLY';

export const DELETE_PRICING_PLAN_API = 'DELETE_PRICING_PLAN_API';
export const DELETE_PRICING_PLAN_API_FAILED = 'DELETE_PRICING_PLAN_API_FAILED';
export const DELETE_PRICING_PLAN_API_SUCCESSFULLY = 'DELETE_PRICING_PLAN_API_SUCCESSFULLY';

export const CREATE_COURSE_PRICING_PLAN_MODE = 'CREATE_COURSE_PRICING_PLAN_MODE';
export const EDIT_COURSE_PRICING_PLAN_MODE = 'EDIT_COURSE_PRICING_PLAN_MODE';

// Voice Command Pricing Plan
export const CREATE_VOICE_COMMAND_PRICING_PLAN_MODE = 'CREATE_VOICE_COMMAND_PRICING_PLAN_MODE';
export const EDIT_VOICE_COMMAND_PRICING_PLAN_MODE = 'EDIT_VOICE_COMMAND_PRICING_PLAN_MODE';
export const VOICE_COMMAND_PRICING_PLANS_URI = '/voice-command-pricing-plans';

// Voice Command Template Notebook
export const CREATE_VOICE_COMMAND_TEMPLATE_NOTEBOOK_MODE =
    'CREATE_VOICE_COMMAND_TEMPLATE_NOTEBOOK_MODE';
export const EDIT_VOICE_COMMAND_TEMPLATE_NOTEBOOK_MODE =
    'EDIT_VOICE_COMMAND_TEMPLATE_NOTEBOOK_MODE';
export const VOICE_COMMAND_TEMPLATE_NOTEBOOKS_URI = '/voice-command-template-notebooks';

export const VOICE_COMMAND_TRAINING_GUIDE_PROJECT_MANAGEMENT_URL = 'https://youtu.be/awMp4BGXoAU';
export const VOICE_COMMAND_TRAINING_GUIDE_CREATE_LABEL_AND_RECORD_AUDIO =
    'https://youtu.be/vp_af44sRdE';
export const VOICE_COMMAND_TRAINING_GUIDE_TRAIN_MODEL = 'https://youtu.be/UymQxoWVJ94';

export const VOICE_COMMAND_ADMIN = '/voice-command-admin';

export const CLOUD_FLARE_VIDEO_CREATE_MODE = 'CLOUD_FLARE_VIDEO_CREATE_MODE';
export const CLOUD_FLARE_VIDEO_EDIT_MODE = 'CLOUD_FLARE_VIDEO_EDIT_MODE';
export const CLOUD_FLARE_VIDEO = 'CloudFlareVideo';

export const YOUTUBE_VIDEO_CREATE_MODE = 'YOUTUBE_VIDEO_CREATE_MODE';
export const YOUTUBE_VIDEO_EDIT_MODE = 'YOUTUBE_VIDEO_EDIT_MODE';

export const CODE_CHALLENGE_CREATE_MODE = 'CODE_CHALLENGE_CREATE_MODE';
export const CODE_CHALLENGE_EDIT_MODE = 'CODE_CHALLENGE_EDIT_MODE';
export const CODE_CHALLENGE_VIEW_MODE = 'CODE_CHALLENGE_VIEW_MODE';

export const CREATE_VIDEO_API = 'CREATE_VIDEO_API';
export const CREATE_VIDEO_API_FAILED = 'CREATE_VIDEO_API_FAILED';
export const CREATE_VIDEO_API_SUCCESSFULLY = 'CREATE_VIDEO_API_SUCCESSFULLY';
export const VIDEOS_URI = '/videos';

// Order Quizzz

export const ORDER_QUIZZ = 'OrderQuizz';
export const ORDER_QUIZZ_ITEM = 'OrderQuizzItem';
export const ORDER_QUIZZ_CREATE_MODE = 'ORDER_QUIZZ_CREATE_MODE';
export const ORDER_QUIZZ_EDIT_MODE = 'ORDER_QUIZZ_EDIT_MODE';
export const ORDER_QUIZZ_VIEW_MODE = 'ORDER_QUIZZ_VIEW_MODE';

export const QUIZZ_VIEW_MODE = 'QUIZZ_VIEW_MODE';
export const QUIZZ_EDIT_MODE = 'QUIZZ_EDIT_MODE';

export const CREATE_QUIZZ_API = 'CREATE_QUIZZ_API';
export const CREATE_QUIZZ_API_FAILED = 'CREATE_QUIZZ_API_FAILED';
export const CREATE_QUIZZ_API_SUCCESSFULLY = 'CREATE_QUIZZ_API_SUCCESSFULLY';
export const QUIZZES_URI = '/quizzes';
export const STORAGE_URI = '/storage';
export const USAGE_URI = '/usage';
export const TFJS_DEMOS_URI = '/tfjs-demos';
export const CODE_CHALLENGES_URI = '/code-challenges';
export const ORDER_QUIZZ_ITEMS_URI = '/order-quizz-items';
export const QUIZZ_QUESTION = 'QUIZZ_QUESTION';
export const QUIZZ_SOLUTION = 'QUIZZ_SOLUTION';
export const QUIZZ_HINTS = 'QUIZZ_HINTS';
export const QUIZZ_ITEMS_URI = '/quizz-items';

// Multiple Choice Quizz
export const MULTIPLE_CHOICE_QUIZZ = 'MultipleChoiceQuizz';
export const MULTIPLE_CHOICE_QUIZZ_ITEM = 'MultipleChoiceQuizzItem';
export const MULTIPLE_CHOICE_QUIZZ_EDIT_MODE = 'MULTIPLE_CHOICE_QUIZZ_EDIT_MODE';
export const MULTIPLE_CHOICE_QUIZZ_VIEW_MODE = 'MULTIPLE_CHOICE_QUIZZ_VIEW_MODE';
export const POLYNOMIAL_TFJS_DEMO = 'PolynomialTfjsDemo';


// TF JS Demos
export const CREATE_TFJS_DEMO_API = 'CREATE_TFJS_DEMO_API';
export const CREATE_TFJS_DEMO_API_FAILED = 'CREATE_TFJS_DEMO_API_FAILED';
export const CREATE_TFJS_DEMO_API_SUCCESSFULLY = 'CREATE_TFJS_DEMO_API_SUCCESSFULLY';

// Code Challenge
export const CREATE_CODE_CHALLENGE_API = 'CREATE_CODE_CHALLENGE_API';
export const CREATE_CODE_CHALLENGE_API_FAILED = 'CREATE_CODE_CHALLENGE_API_FAILED';
export const CREATE_CODE_CHALLENGE_API_SUCCESSFULLY = 'CREATE_CODE_CHALLENGE_API_SUCCESSFULLY';

export const CODE_CHALLENGE_QUESTION = 'CODE_CHALLENGE_QUESTION';
export const CODE_CHALLENGE_SOLUTION = 'CODE_CHALLENGE_SOLUTION';
export const CODE_CHALLENGE_HINTS = 'CODE_CHALLENGE_HINTS';

// List course works

export const LIST_COURSE_WORKS_API = 'LIST_COURSE_WORKS_API';
export const LIST_COURSE_WORKS_API_FAILED = 'LIST_COURSE_WORKS_API_FAILED';
export const LIST_COURSE_WORKS_API_SUCCESSFULLY = 'LIST_COURSE_WORKS_API_SUCCESSFULLY';

// Post
export const POSTS_URI = '/posts';
export const NEWS_URI = '/news';
export const MESSAGES_URI = '/messages';
export const MEMBERS_URI = '/members';
export const CONVERSATIONS_URI = '/conversations';
export const PRIVATE = 'PRIVATE';
export const PUBLIC = 'PUBLIC';
export const COURSE_CONVERSATION = 'CourseConversation';
export const MATERIAL = 'Material';

// Grade History.
export const GRADE_HISTORIES_URI = '/grade-histories';

// Extract Code Mode
export const FUNCTION_ONLY = 'FUNCTION_ONLY';
export const ALL_CODE = 'ALL_CODE';

export const DIRECT_UPLOAD_URL = `${HOST}/videos/direct-upload`;

// SOME User Guide need to Have
export const GUIDE_FOR_FIRST_TIME_LOG_IN = 'guide-for-first-time-log-in';
export const GUIDE_FOR_FIRST_TIME_CREATE_MATERIAL = 'guide-for-first-time-create-material';
export const GUIDE_FOR_FIRST_TIME_SMART_BLOG = 'guide-for-first-time-smart-blog';

// rewards

export const REWARDS_URI = '/rewards';
export const REWARD_MINIMUM_MIN_POINT = 0;
export const REWARD_MAXIMUM_MAX_POINT = 100000;

export const allowMimeTypes = [
    'application/vnd.jupyter',
    'application/vnd.jupyter.cells',
    'application/vnd.jupyter.dragindex',
    'application/x-ipynb+json',
    'text/html',
    'application/json',
    'application/ld+json',
    'text/x-python',
    'application/x-python-code',
    'image/png',
    'image/jpeg',
    'image/gif',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const USER_STORAGE_DEFAULT_CAPACITY = 500 * 1024 * 1024;
export const DEFAULT_SINGLE_FILE_MAXIMUM_SIZE = 30 * 1024 * 1024;
export const DEFAULT_SINGLE_VIDEO_MAXIMUM_SIZE = 300 * 1024 * 1024;
export const STORAGE_EXCEEDS_LIMIT = 'STORAGE_EXCEEDS_LIMIT';
export const PRIVATE_FILES_URI = '/private-files';
export const PUBLIC_FILES_URI = '/public-files';
export const IMAGE = 'IMAGE';

// Beta Page
export const BETA_VIDEO_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/1306-video-card-computer-outline-1.gif';
export const BETA_QUIZZ_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/1142-form-sheet-questionnaire-outline.gif';
export const BETA_DOCUMENT_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/1019-document-signature-hand-outline.gif';
export const BETA_CODE_ICON = 'https://storage.googleapis.com/protonx-cloud-storage/icons/code.gif';

export const SMART_HR_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/245-edit-document-outline.png';

export const SMART_EDITOR_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/35-edit-outline.png';

export const SMART_BLOG_ICON =
    'https://storage.googleapis.com/protonx-cloud-storage/icons/471-ebook-reader-outline.png';

export const STUDIO_PRICING_PLANS_URI = '/studio-pricing-plans';

export const CREATE_PRICING_PLAN_MODE = 'CREATE__PRICING_PLAN_MODE';
export const EDIT_PRICING_PLAN_MODE = 'EDIT_PRICING_PLAN_MODE';
export const STUDIO_JOIN_REQUESTS_URI = '/studio-join-requests';

export const PROTONX_DUMMY_USER_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InN0dWRpb2R1bW15dXNlckBnbWFpbC5jb20iLCJpYXQiOjE2NzExNjE1MzQsImV4cCI6MTY3Mzc1MzUzNH0.9RaW8EHs-dOKk7AJlbClwRd6qwjVpQtLxH2LuJkJyjs';
// 1 month: From 13/09/2022

export const PRODUCT_LINK = 'https://protonx.ai/san-pham/';

export const GENERAL = 'General';
export const LITERARY = 'Literary';

export const SmartEditorStype = [
    {
        value: 'General',
        text: 'General',
        color: 'info',
    },
    // {
    //     value: 'Literary',
    //     text: 'Literary',
    //     color: 'primary',
    // },
];

export const FASHION_KEYWORD = 'FASHION_KEYWORD';
export const FASHION_OPENING_TEXT = 'FASHION_OPENING_TEXT';
export const FASHION_GENERATED_SEQUENCE = 'FASHION_GENERATED_SEQUENCE';
export const FASHION_CONTENT = 'FASHION_CONTENT';
export const JD_CONTENT = 'JD_CONTENT';

// Email verification
export const EMAIL_SENT = 'EMAIL_SENT';
export const ACCOUNT_VERIFIED = 'ACCOUNT_VERIFIED';
export const ACCOUNT_VERIFY = 'ACCOUNT_VERIFY';

export const SIGNUP_URI = '/signup';

export const LOGIN = 'login';
export const SIGNUP = 'signup';

export const CREATE_GUIDE_MODE = 'CREATE_GUIDE_MODE';
export const EDIT_GUIDE_MODE = 'EDIT_GUIDE_MODE';

export const CREATE_GUIDE_STEP_MODE = 'CREATE_GUIDE_STEP_MODE';
export const EDIT_GUIDE_STEP_MODE = 'EDIT_GUIDE_STEP_MODE';

// Feedback
export const ContentFeedbackType = [
    {
        value: 'CONTENT',
        text: 'Content',
    },
    {
        value: 'DISPLAY',
        text: 'Display',
    },
    {
        value: 'OTHER',
        text: 'Other',
    },
];

export const FEEDBACKS_URI = '/feedbacks';
// Studio content
export const JD_TITLE = 'JD_TITLE';
export const BLOG_TITLE = 'BLOG_TITLE';
export const JD_GENERATED_SEQUENCE = 'BLOG_GENERATED_SEQUENCE';
