import { takeLatest } from 'redux-saga/effects';

import { ATTEMPT_LOGIN, ATTEMPT_REGISTER, GET_USER_IDENTITY, GG_ATTEMPT_LOGIN } from '../constant';

import { attemptLoginSaga } from './attemptLoginSaga';
import { ggAttemptLoginSaga } from './ggAttempLoginSaga';
import { attemptRegisterSaga } from './attemptRegisterSaga';
import { getUserIdentitySaga } from './getUserIdentitySaga';

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {
    // Login
    yield takeLatest(ATTEMPT_LOGIN, attemptLoginSaga);
    // GG Login
    yield takeLatest(GG_ATTEMPT_LOGIN, ggAttemptLoginSaga);
    // Register
    yield takeLatest(ATTEMPT_REGISTER, attemptRegisterSaga);
    // Get User Identity
    yield takeLatest(GET_USER_IDENTITY, getUserIdentitySaga);
    // Create TFJS DEmo
}
