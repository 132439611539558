import * as React from 'react';

function SvgCustomVN(props) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg" 
            width='1em'
			height='1em'
            x="0px" y="0px" viewBox="0 0 55.2 38.4" 
            className='svg-icon'
			{...props}
        >
            {/* <style type="text/css">.st0{fill:#DA251D;} .st1{fill:#FFFF00;}</style> */}
        <g>
        <path 
            className="st0"
            fill='#DA251D' 
            d="M3.01,0h49.17c1.66,0.01,3.01,1.37,3.01,3.03v32.33c0,1.66-1.35,3.02-3.01,3.03H3.01 C1.35,38.39,0,37.03,0,35.37V3.03C0,1.37,1.35,0.01,3.01,0L3.01,0z" />
        <path 
            className="st1"
            fill='#FFFF00'
            d="M27.6,11.52l-2.37,7.3l4.87,1.58L27.6,11.52L27.6,11.52z M27.6,11.52L27.6,11.52L27.6,11.52L27.6,11.52z" />
                <path fill='#FFFF00' className="st1" d="M27.6,11.52l2.37,7.3l-4.87,1.58L27.6,11.52L27.6,11.52z M27.6,11.52L27.6,11.52L27.6,11.52L27.6,11.52z" />
                <path fill='#FFFF00' className="st1" d="M34.9,16.83h-7.68v5.12L34.9,16.83L34.9,16.83z M34.9,16.83L34.9,16.83L34.9,16.83L34.9,16.83z" />
                <path fill='#FFFF00' className="st1" d="M34.9,16.83l-6.21,4.51l-3.01-4.14L34.9,16.83L34.9,16.83z M34.9,16.83L34.9,16.83L34.9,16.83L34.9,16.83z" />
                <path fill='#FFFF00' className="st1" d="M32.11,25.41l-2.37-7.3l-4.87,1.58L32.11,25.41L32.11,25.41z M32.11,25.41L32.11,25.41L32.11,25.41 L32.11,25.41z" />
                <path fill='#FFFF00' className="st1" d="M32.11,25.41L25.9,20.9l3.01-4.14L32.11,25.41L32.11,25.41z M32.11,25.41L32.11,25.41L32.11,25.41L32.11,25.41 z" />
                <path fill='#FFFF00' className="st1" d="M20.3,16.83h7.68v5.12L20.3,16.83L20.3,16.83z M20.3,16.83L20.3,16.83L20.3,16.83L20.3,16.83z" />
                <path fill='#FFFF00' className="st1" d="M20.3,16.83l6.21,4.51l3.01-4.14L20.3,16.83L20.3,16.83z M20.3,16.83L20.3,16.83L20.3,16.83L20.3,16.83z" />
                <path fill='#FFFF00' className="st1" d="M23.09,25.41l2.37-7.3l4.87,1.58L23.09,25.41L23.09,25.41z M23.09,25.41L23.09,25.41L23.09,25.41L23.09,25.41z" />
                <path fill='#FFFF00' className="st1" d="M23.09,25.41l6.21-4.51l-3.01-4.14L23.09,25.41L23.09,25.41z M23.09,25.41L23.09,25.41L23.09,25.41 L23.09,25.41z" />
        </g></svg>
    );
}

export default SvgCustomVN;
