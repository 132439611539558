import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import allReducers from './reducers';
import { watcherSaga } from './sagas/index';

const reduxSagaMonitorOptions = {};

const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [sagaMiddleware];
const enhancers = [applyMiddleware(...middlewares)];

// Redux store
const Store = createStore(allReducers, composeEnhancer(...enhancers));

// run the saga
sagaMiddleware.run(watcherSaga);

export default Store;
