import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';

const Brand = ({ asideStatus, setAsideStatus }) => {
    return (
        <div className='brand' style={{ marginLeft: '7%' }}>
            <div>
                <div className='brand-logo'>
                    <h1 className='brand-title '>
                        <Link to='/' aria-label='Logo' alt='ProtonX'>
                            {/* <Logo height={32} /> */}
                            <img
                                src='https://storage.googleapis.com/protonx-mle-production/Studio.-logo.svg'
                                className='App-logo'
                                alt='logo'
                                width='90'
                            />
                        </Link>
                    </h1>
                </div>
            </div>
            <button
                type='button'
                className='btn brand-aside-toggle'
                aria-label='Toggle Aside'
                onClick={() => setAsideStatus(!asideStatus)}>
                <Icon icon='FirstPage' className='brand-aside-toggle-close' />
                <Icon icon='LastPage' className='brand-aside-toggle-open' />
            </button>
        </div>
    );
};
Brand.propTypes = {
    asideStatus: PropTypes.bool.isRequired,
    setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
