import axios from 'axios';
import { HOST, STUDIO_JOIN_REQUESTS_URI, TOKEN_KEY } from '../../constant';
import { getTokenFromLocalStorage } from '../../utils';

export function getStudioJoinRequestApiCall(data) {
    const { uid } = data;
    const token = getTokenFromLocalStorage(TOKEN_KEY);
    return axios({
        method: 'get',
        url: `${HOST}${STUDIO_JOIN_REQUESTS_URI}/users/${uid}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}
