import axios from 'axios';
import { HOST, USERS_URI, USERS_CONFIRM_URI } from '../constant';

export function attemptRegisterApiCall(user) {
    return axios({
        method: 'post',
        url: `${HOST}${USERS_URI}`,
        // FIX_ME : replace mock data
        data: user,
    });
}

export function confirmRegisterApiCall(emailToken) {
    return axios({
        method: 'post',
        url: `${HOST}${USERS_CONFIRM_URI}/${emailToken}`,
    });
}
