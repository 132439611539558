import {
    ATTEMPT_LOGIN,
    ATTEMPT_LOGIN_SUCCESSFULLY,
    ATTEMPT_LOGIN_FAILED,
    ATTEMPT_REGISTER,
    ATTEMPT_REGISTER_SUCCESSFULLY,
    ATTEMPT_REGISTER_FAILED,
    GET_USER_IDENTITY_SUCCESSFULLY,
    GET_USER_IDENTITY_FAILED,
    GET_USER_IDENTITY,
    GG_ATTEMPT_LOGIN,
    GG_ATTEMPT_LOGIN_FAILED,
    GG_ATTEMPT_LOGIN_SUCCESSFULLY,
    CREATE_COURSE_API,
    CREATE_COURSE_API_SUCCESSFULLY,
    UPDATE_USER_COURSES_AFTER_PATCH_COURSE,
    UPDATE_USER_COURSES_AFTER_DELETE_COURSE,
    LOG_OUT,
} from '../constant';

const defaultUser = {
    isGettingUserIdentity: false,
    isGGAttempingLogin: false,
    errors: null,
    isCreatingCourse: false,
};

export default (user = defaultUser, action) => {
    if (!action) {
        return user;
    }

    switch (action.type) {
        // Login
        case ATTEMPT_LOGIN:
            return { ...user, ...{ isLoading: true, errors: null } };
        case ATTEMPT_LOGIN_SUCCESSFULLY:
            return { ...action.payload, ...{ isLoading: false } };
        case ATTEMPT_LOGIN_FAILED:
            return {
                ...user,
                ...{ isLoading: false },
                ...{ errors: action.payload },
            };
        // GG Login
        case GG_ATTEMPT_LOGIN:
            return { ...user, ...{ isGGAttempingLogin: true, errors: null } };
        case GG_ATTEMPT_LOGIN_SUCCESSFULLY:
            return { ...action.payload, ...{ isGGAttempingLogin: false } };
        case GG_ATTEMPT_LOGIN_FAILED:
            return {
                ...user,
                ...{ isGGAttempingLogin: false },
                ...{ errors: action.payload },
            };
        // Register
        case ATTEMPT_REGISTER:
            return { ...user, ...{ isLoading: true } };
        case ATTEMPT_REGISTER_SUCCESSFULLY:
            return { ...action.payload, ...{ isLoading: false } };
        case ATTEMPT_REGISTER_FAILED:
            return {
                ...user,
                ...{ isLoading: false },
                ...{ errors: action.payload },
            };
        // Identity
        case GET_USER_IDENTITY:
            return { ...user, ...{ isGettingUserIdentity: true } };
        case GET_USER_IDENTITY_SUCCESSFULLY:
            return { ...user, ...action.payload, ...{ isGettingUserIdentity: false } };
        case GET_USER_IDENTITY_FAILED:
            return {
                ...user,
                ...{ errors: action.payload },
                ...{ isGettingUserIdentity: false },
            };
        // Create Course
        case CREATE_COURSE_API:
            return { ...user, ...{ isCreatingCourse: true } };

        // Create Course
        case CREATE_COURSE_API_SUCCESSFULLY:
            try {
                const course = action.payload;
                const teacherCourses = user?.teacherCourses;
                return {
                    ...user,
                    ...{ isCreatingCourse: false },
                    ...{
                        teacherCourses: [...teacherCourses, course],
                    },
                };
            } catch (e) {
                return {
                    ...user,
                    ...{ isCreatingCourse: false },
                };
            }
        // Update when user patches course.
        case UPDATE_USER_COURSES_AFTER_PATCH_COURSE:
            try {
                const updatedCourse = action.payload;
                const teacherCourses = user?.teacherCourses;
                let courseIndex;
                for (let i = 0; i < teacherCourses.length; i += 1) {
                    if (teacherCourses[i]?._id === updatedCourse._id) {
                        courseIndex = i;
                    }
                }

                if (courseIndex === undefined || courseIndex === null) {
                    return {
                        ...user,
                    };
                }
                const newTeacherCoursesToSave = [
                    ...teacherCourses.slice(0, courseIndex),
                    {
                        ...teacherCourses[courseIndex],
                        ...updatedCourse,
                    },
                    ...teacherCourses.slice(courseIndex + 1),
                ];
                return {
                    ...user,
                    ...{
                        teacherCourses: newTeacherCoursesToSave,
                    },
                };
            } catch (e) {
                return {
                    ...user,
                };
            }
        case UPDATE_USER_COURSES_AFTER_DELETE_COURSE:
            try {
                const courseId = action.payload;
                const teacherCourses = user?.teacherCourses;
                let courseIndex;
                for (let i = 0; i < teacherCourses.length; i += 1) {
                    if (teacherCourses[i]?._id === courseId) {
                        courseIndex = i;
                    }
                }

                if (courseIndex === undefined || courseIndex === null) {
                    return {
                        ...user,
                    };
                }
                const newTeacherCoursesToSave = [
                    ...teacherCourses.slice(0, courseIndex),
                    ...teacherCourses.slice(courseIndex + 1),
                ];
                return {
                    ...user,
                    ...{
                        teacherCourses: newTeacherCoursesToSave,
                    },
                };
            } catch (e) {
                return {
                    ...user,
                };
            }
        case LOG_OUT:
            return defaultUser;
        default:
            return {
                ...user,
            };
    }
};
