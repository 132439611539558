import axios from 'axios';
import { HOST, LOGIN_URI } from '../constant';

export function attemptLoginApiCall(user) {
    return axios({
        method: 'post',
        url: `${HOST}${LOGIN_URI}`,
        // FIX_ME : replace mock data
        data: user,
    });
}
