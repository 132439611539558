import { call, put } from 'redux-saga/effects';

import { ggAttemptLoginApiCall } from '../apiCalls/ggAttemptLoginApiCall';

import { ggAttemptLoginFailed, ggAttemptLoginSuccessfully } from '../actions/ggAttempLoginAction';

import { setTokenToLocalStorage } from '../utils';

import { TOKEN_KEY, ECONNABORTED, SERVER_ERROR } from '../constant';

export function* ggAttemptLoginSaga(token) {
    try {
        const res = yield call(ggAttemptLoginApiCall, token.payload);
        if (res.status === 200) {
            const user = res.data;
            if (user.token && user.token !== '') {
                yield put(ggAttemptLoginSuccessfully(res.data));
                // Set userToken to Local Storage
                yield call(setTokenToLocalStorage, TOKEN_KEY, user.token);
            } else {
                yield put(ggAttemptLoginFailed(SERVER_ERROR));
            }
        } else if (res?.code === ECONNABORTED) {
            // Time Out
            yield put(ggAttemptLoginFailed(SERVER_ERROR));
        } else {
            let errors = res?.response?.data;
            // Server is down or other reasons.
            if (!errors) {
                errors = SERVER_ERROR;
            }
            yield put(ggAttemptLoginFailed(errors));
        }
    } catch (err) {
        yield put(ggAttemptLoginFailed(SERVER_ERROR));
    }
}
