import React, { useContext, useLayoutEffect, useRef, useEffect, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification, { store } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import {
    demoPages,
    healthPath,
    dashboardMenu,
    mleMenu,
    materialsMenu,
    featuresDemos,
    smartEditor,
    smartEditorHR,
    smartPost,
    contentMenu,
    smartBlog,
} from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import Store from '../store';
import { getUserIdentity } from '../actions/getUserIdentityAction';
import {
    CODE,
    SOME_THING_WENT_WRONG,
    TOKEN_KEY,
    readableCommonError,
    TOKEN_IS_EXPIRED,
    PENDING,
    SUCCESSFUL,
    APPROVED,
} from '../constant';
import Spinner from '../components/bootstrap/Spinner';
import Page from '../layout/Page/Page';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import { logOut } from '../actions/logoutAction';
import { getVersionApiCall } from '../apiCalls/getVersionApiCall';
import VersionModal from './VersionModal';
import PremiumUserModal from './PremiumUserModal';
import { getUserIdentityApiCall } from '../apiCalls/getUserIdentityApiCall';
import { getStudioJoinRequestApiCall } from '../apiCalls/studio/getStudioJoinRequestApiCall';
import { postErrorCheckResponse } from '../utils';
import { PREMIUM_USER, USER } from '../authorizationConstant';

const ObjectID = require('bson-objectid');

const isByPassed = (pathname) => {
    /** Ignore for health check + courses */
    const withoutTokenRoutes = [
        healthPath.health.path,
        dashboardMenu.summary.path,
        // mleMenu.mle.path,
        // materialsMenu?.intro?.path,
        // materialsMenu?.create?.path,
        // materialsMenu?.subscription?.path,
        featuresDemos?.smartEditor?.path,
        smartEditor?.path,
        smartEditorHR?.path,
        smartPost?.path,
        smartBlog?.path,
        contentMenu?.list?.path,
    ];
    try {
        if (withoutTokenRoutes.includes(pathname)) {
            return true;
        }
        // exact path: /courses/:id
        const names = pathname.split('/');
        if (names?.length === 3 && names[1] === 'courses' && ObjectID.isValid(names[2])) {
            return true;
        }
        if (names?.length === 5 && names[1] === 'verify-users' && ObjectID.isValid(names[2])) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};

const goToLoginWithRedirect = (history, location) => {
    const redirectUri = location?.pathname + location?.search;
    if (location?.pathname !== demoPages.login.path) {
        const logingWithRedirectURL = `${demoPages.login.path}?redirect_to=${encodeURIComponent(
            redirectUri,
        )}`;
        history.push(logingWithRedirectURL);
    } else {
        history.push(demoPages.login.path);
    }
};

const useInterval = (handler, interval, isPending) => {
    const [intervalId, setIntervalId] = useState();
    useEffect(() => {
        let id;
        // Only run interval when the video is playing has this video has script
        if (isPending) {
            id = setInterval(handler, interval);
            setIntervalId(id);
        }
        return () => clearInterval(id);
    }, [isPending]);
    return () => clearInterval(intervalId);
};

function App() {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation(['translation', 'menu']);
    const user = useSelector((state) => state.user);
    const { isGettingUserIdentity } = user;

    /** Version Modal */
    const [displayVersionModalStatus, setDisplayVersionModalStatus] = useState(false);
    const [versionInfo, setVersionInfo] = useState({});
    /** Version Modal */

    /** Studio Join Request */
    const [studioJoinRequest, setStudioJoinRequest] = useState({});

    /** Studio Join Request */

    /** Popup Upgrade */
    const [displayPremiumModalStatus, setDisplayPremiumModalStatus] = useState(false);
    /** Popup Upgrade */

    /** */
    let isPreimium = false;
    let isAdmin = false;
    const premiumRole = user?.role;
    const premiumUsers = ['Admin', 'PremiumUser'];
    if (premiumRole !== undefined && premiumRole !== null && premiumUsers.includes(premiumRole)) {
        isPreimium = true;
    }

    if (premiumRole === 'Admin') {
        isAdmin = true;
    }

    /** */
    // Only loading when API has not returned yet
    // Get user identity
    useEffect(() => {
        try {
            const token = localStorage.getItem(TOKEN_KEY);
            // Token exists: Get user identity.
            if (token) {
                Store.dispatch(getUserIdentity(token));
                // Token does not exist + current location is not in skip list
                // Create redirect_to after login.
            } else if (!token && !isByPassed(location.pathname)) {
                goToLoginWithRedirect(history, location);
                // Token does not exist + current location is in skip list
                // Allow user to go to this page
            } else if (!token && isByPassed(location.pathname)) {
                history.push(location.pathname + location?.search);
            } else {
                // Other case, redirect tor login.
                history.push(demoPages.login.path);
            }
        } catch (e) {
            // FIXME.
        }
    }, [history]);

    // If failed to identity. Redirect to Login Page.
    useEffect(() => {
        if (
            user?.errors !== null &&
            user?.errors !== undefined &&
            Object.keys(user.errors).includes(CODE)
        ) {
            const code = user.errors?.code;
            if (code === 401) {
                // Clear Storage
                localStorage.removeItem(TOKEN_KEY);
                // Redirect to Login
                goToLoginWithRedirect(history, location);
                // Dispatch Logout
                Store.dispatch(logOut());
                showNotification(
                    <Icon icon='Error' className='h1' />,
                    <div className='row d-flex align-items-center'>
                        <div className='col-auto h5'>
                            {t(readableCommonError[TOKEN_IS_EXPIRED])}
                        </div>
                    </div>,
                );
            } else {
                localStorage.removeItem(TOKEN_KEY);
                goToLoginWithRedirect(history, location);
                showNotification(
                    <Icon icon='Error' className='h1' />,
                    <div className='row d-flex align-items-center'>
                        <div className='col-auto h5'>
                            {t(readableCommonError[SOME_THING_WENT_WRONG])}
                        </div>
                    </div>,
                );
            }
        }
    }, [user]);
    // Add crisp

    useEffect(() => {
        // Include the Crisp code here, without the <script></script> tags
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = '9ecd168f-d390-40fd-a866-4a2b335dfa72';
        (() => {
            const d = document;
            const s = d.createElement('script');
            s.src = 'https://client.crisp.chat/l.js';
            s.async = 1;
            d.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, []);
    // Auto save version when reload the page.

    useEffect(() => {
        getVersionApiCall().then((getResult) => {
            if (getResult?.data?.client?.version) {
                localStorage.setItem('verison', getResult?.data?.client?.version);
                setVersionInfo(getResult?.data);
            }
        });
    }, []);

    // // Check user current studion join request.
    // const [waitingForApproval, setWaitingForApproval] = useState(false);
    // useEffect(() => {
    //     getStudioJoinRequestApiCall({ uid: user?._id }).then((getResult) => {
    //         if (getResult?.status === 200) {
    //             if (getResult?.data?.status === PENDING && !waitingForApproval) {
    //                 setWaitingForApproval(true);
    //             }
    //             setStudioJoinRequest(getResult?.data || {});
    //         }
    //     });
    // }, [user?._id]);

    // // Check student join request intervally
    // useInterval(
    //     () => {
    //         getStudioJoinRequestApiCall({ uid: user?._id }).then((getResult) => {
    //             if (getResult?.status === 200) {
    //                 if (
    //                     studioJoinRequest?.status === PENDING &&
    //                     getResult?.data?.status === APPROVED
    //                 ) {
    //                     if (!displayPremiumModalStatus) {
    //                         // setStudioJoinRequest(getResult?.data || {});
    //                         setDisplayPremiumModalStatus(true);
    //                         setWaitingForApproval(false);
    //                     }
    //                 }
    //             }
    //         });
    //     },
    //     1000 * 10, // 5 minutes/check to improve experience.
    //     waitingForApproval,
    // );

    // Check version
    useInterval(
        () => {
            getVersionApiCall().then((getResult) => {
                const currentVersion = localStorage.getItem('verison');
                let newVersion;
                if (getResult?.data?.client?.version) {
                    newVersion = getResult?.data?.client?.version;
                    localStorage.setItem('verison', newVersion);
                    setVersionInfo(getResult?.data);
                }

                if (currentVersion !== newVersion && !displayVersionModalStatus) {
                    setDisplayVersionModalStatus(true);
                }
            });
        },
        1000 * 60 * 60 * 2, // 60 minutes/check to improve experience.
        true,
    );

    // Always listen to user identity.
    // If token is expired, reload the page.
    /**
    useEffect(() => {
        Store.subscribe(() => {
            const state = Store.getState();
            const code = state?.user.errors?.code;
            if (code === 401) {
                // Reload the page
                history.go(0);
                localStorage.removeItem(TOKEN_KEY);
                showNotification(
                    <Icon icon='Error' className='h1' />,
                    <div className='row d-flex align-items-center'>
                        <div className='col-auto h5'>
                            {t(readableCommonError[TOKEN_IS_EXPIRED])}
                        </div>
                    </div>,
                );
            }
        });
    }, [user, history]);
    */

    const { fullScreenStatus, setFullScreenStatus, ...themeContext } = useContext(ThemeContext);

    const ref = useRef(null);

    useFullscreen(ref, fullScreenStatus, {
        onClose: () => setFullScreenStatus(false),
    });

    useLayoutEffect(() => {
        if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
            document.body.classList.add('modern-design');
        } else {
            document.body.classList.remove('modern-design');
        }
    });

    //	Add paths to the array that you don't want to be "Aside".
    // const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];
    const withOutAsidePages = [demoPages.login.path];

    const loadingPage = (
        <Page container='fluid'>
            <div className='row'>
                <div>
                    <Spinner color='secondary' isGrow>
                        Loading...
                    </Spinner>
                </div>
            </div>
        </Page>
    );

    const generateUI = () => {
        let mainPage;
        if (isGettingUserIdentity) {
            mainPage = loadingPage;
        } else if (user?.email !== undefined && user?.email !== null && typeof user === 'object') {
            mainPage = (
                <div
                    ref={ref}
                    className='app'
                    style={{
                        backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
                        zIndex: fullScreenStatus && 1,
                    }}>
                    <Switch location={location}>
                        {withOutAsidePages.map((path) => (
                            <Route key={path} path={path} exact component={Wrapper} />
                        ))}
                        <Route>
                            <Aside isPreimium={isPreimium} isAdmin={isAdmin} />
                            <Wrapper />
                            <VersionModal
                                setIsOpen={setDisplayVersionModalStatus}
                                isOpen={displayVersionModalStatus}
                                t={t}
                                versionInfo={versionInfo}
                            />
                            <PremiumUserModal
                                setIsOpen={setDisplayPremiumModalStatus}
                                isOpen={displayPremiumModalStatus}
                                t={t}
                            />
                        </Route>
                    </Switch>
                </div>
            );
        } else {
            // Non-User
            // Non-User is able to see course.
            const withOutAsidePagesForNonUers = [
                demoPages.login.path,
                // dashboardMenu.courses.path,
                dashboardMenu.summary.path,
                demoPages.verify.path,
                // mleMenu.mle.path,
                // materialsMenu?.intro?.path,
            ];
            mainPage = (
                <div
                    ref={ref}
                    className='app'
                    style={{
                        backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
                        zIndex: fullScreenStatus && 1,
                    }}>
                    <Switch location={location}>
                        {withOutAsidePagesForNonUers.map((path) => (
                            <Route key={path} path={path} component={Wrapper} />
                        ))}
                    </Switch>
                </div>
            );
        }

        return mainPage;
    };

    return (
        <ThemeProvider theme={themeContext?.appData}>
            <ToastProvider components={{ ToastContainer, Toast }}>
                {generateUI()}
                <Portal id='portal-notification'>
                    <ReactNotification />
                </Portal>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default connect()(App);
