import axios from 'axios';

import { HOST, VERSION_URI, TIME_OUT } from '../constant';

export function getVersionApiCall(token) {
    return axios({
        method: 'get',
        timeout: TIME_OUT,
        url: `${HOST}${VERSION_URI}`,
        data: {
            token,
        },
    })
        .then((res) => res)
        .catch((err) => err);
}
