import { ATTEMPT_LOGIN, ATTEMPT_LOGIN_FAILED, ATTEMPT_LOGIN_SUCCESSFULLY } from '../constant';

export const attemptLogin = (user) => ({
    type: ATTEMPT_LOGIN,
    payload: user,
});

export const attemptLoginFailed = (error) => ({
    type: ATTEMPT_LOGIN_FAILED,
    payload: error,
});

export const attemptLoginSuccessfully = (user) => ({
    type: ATTEMPT_LOGIN_SUCCESSFULLY,
    payload: user,
});
