export const homeMenu = {
    intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
    // bootstrap: {
    //     id: 'searchengine',
    //     text: 'Search Engine',
    //     path: '#searchengine',
    //     icon: 'ImageSearch',
    //     subMenu: null,
    // },
    // storybook: {
    //     id: 'mle',
    //     text: 'MLE',
    //     path: '#mle',
    //     icon: 'CustomStorybook',
    //     subMenu: null,
    // },
    // formik: {
    //     id: 'formik',
    //     text: 'Formik',
    //     path: '#formik',
    //     icon: 'CheckBox',
    //     subMenu: null,
    // },
    // apex: {
    //     id: 'apex',
    //     text: 'Apex Charts',
    //     path: '#apex',
    //     icon: 'AreaChart',
    //     subMenu: null,
    // },
};

export const storageMenu = {
    bootstrap: {
        id: 'mleTag',
        text: 'Storage',
        icon: 'Extension',
    },
    storage: {
        id: 'storage',
        text: 'Storage',
        icon: 'Storage',
        path: '/storage',
        subMenu: {
            summary: {
                id: 'storage-summary',
                text: 'Summary',
                path: '/storage/summary',
                icon: 'Info',
            },
            files: {
                id: 'storage-files',
                text: 'Files',
                path: '/storage/files',
                icon: 'Folder',
            },
        },
    },
};

export const contentMenu = {
    list: {
        id: 'contents-list',
        text: 'Your content',
        path: '/contents/list',
        icon: 'SpaceDashboard',
    },
};

export const materialsMenu = {
    bootstrap: {
        id: 'mleTag',
        text: 'Studio.',
        icon: 'Extension',
    },
    intro: {
        id: 'materials-introduction',
        text: 'Introduction',
        path: '/materials/intro',
        icon: 'ViewAgenda',
    },
    create: {
        id: 'materials-create',
        text: 'Content Creation',
        path: '/materials/list',
        icon: 'Markdown',
    },
    subscription: {
        id: 'materials-create',
        text: 'Your subscription',
        path: '/materials/subscription',
        icon: 'Subscriptions',
    },
    // dashboard: {
    //     id: 'materials',
    //     text: 'Content Bank',
    //     path: '/materials',
    //     icon: 'DriveFileRenameOutline',
    //     subMenu: {
    //         intro: {
    //             id: 'materials-introduction',
    //             text: 'Introduction',
    //             path: '/materials/intro',
    //             icon: 'ViewAgenda',
    //         },
    //         create: {
    //             id: 'materials-create',
    //             text: 'Content Creation',
    //             path: '/materials/list',
    //             icon: 'Markdown',
    //         },
    //     },
    // },
};

export const adminMenu = {
    bootstrap: {
        id: 'mleTag',
        text: 'Admin',
        icon: 'Extension',
    },
    studioManagement: {
        id: 'studio-management',
        text: 'Studio admin',
        path: '/admin/studio-management',
        icon: 'ViewAgenda',
    },
    contentManagement: {
        id: 'content-management',
        text: 'Content management',
        path: '/admin/content-management',
        icon: 'card-text',
    },
};

export const mleMenu = {
    bootstrap: {
        id: 'mleTag',
        text: 'MLE',
        icon: 'Extension',
    },
    mle: {
        id: 'mle',
        text: 'MLE',
        path: '/mle',
        icon: 'UiRadios',
        subMenu: null,
    },
};

export const labelingMenu = {
    bootstrap: {
        id: 'labelingTag',
        text: 'AI Building Tools',
        icon: 'TagsFill',
    },
    // audioCommands: {
    //     id: 'audioCommands',
    //     text: 'Audio Commands',
    //     path: '/audio-commands/create',
    //     icon: 'RecordCircle',
    //     subMenu: null,
    // },
    audioCommandsProjects: {
        id: 'voiceCommandsProjects',
        text: 'Voice Commands',
        path: '/voice-commands/projects',
        icon: 'RecordCircle',
        subMenu: null,
    },
};

export const dashboardMenu = {
    summary: {
        id: 'summary',
        text: 'Introduction',
        path: '/',
        icon: 'MenuButtonWideFill',
        subMenu: null,
    },
    // newsfeed: {
    //     id: 'newsfeed',
    //     text: 'News Feed',
    //     path: '/news',
    //     icon: 'AppIndicator',
    // },
    // courses: {
    //     id: 'courses',
    //     text: 'Courses',
    //     icon: 'Dashboard',
    //     path: '/courses',
    //     subMenu: {
    //         courses: {
    //             id: 'your-courses',
    //             text: 'Your Courses',
    //             path: '/courses',
    //             icon: 'Front',
    //         },
    //         joinRequests: {
    //             id: 'join-requests',
    //             text: 'Your Requests',
    //             path: '/join-requests',
    //             icon: 'ForwardToInbox',
    //         },
    //     },
    // },
};

export const subscriptionMenu = {
    subscription: {
        id: 'subscription',
        text: 'Your subscription',
        path: '/subscription',
        icon: 'Subscriptions',
    },
};

export const innerNewsFeedPath = {
    posts: {
        id: 'your-posts',
        text: 'Your Posts',
        path: '/posts',
        icon: 'ViewStacked',
    },
};

export const demoPages = {
    // auth: {
    //     id: 'auth',
    //     text: 'Auth Pages',
    //     icon: 'Extension',
    // },
    login: {
        id: 'login',
        text: 'Login',
        path: '/login',
        icon: 'Login',
    },
    // signUp: {
    //     id: 'signUp',
    //     text: 'Sign Up',
    //     path: '/auth-pages/sign-up',
    //     icon: 'PersonAdd',
    // },

    page404: {
        id: 'Page404',
        text: '404 Page',
        path: '/404',
        icon: 'ReportGmailerrorred',
    },

    app: {
        id: 'app',
        text: 'Apps',
        icon: 'Extension',
    },
    verify: {
        id: 'verify-users',
        text: 'Verify users',
        path: '/verify-users',
        icon: 'Shield check',
    },
};

export const featuresDemos = {
    id: 'featuresDemos',
    path: '/feature-demos',
    smartEditor: {
        id: 'smartEditor',
        path: '/feature-demos/smart-editor',
    },
};

export const smartEditor = {
    id: 'smartEditor',
    path: '/smart-editor',
};

export const smartEditorHR = {
    id: 'smartHr',
    path: '/smart-hr',
};

export const smartPost = {
    id: 'smartPost',
    path: '/smart-post',
};

export const smartBlog = {
    id: 'smartBlog',
    path: '/smart-blog',
};

export const layoutMenu = {
//     layoutTypes: {
//         id: 'layoutTypes',
//         text: 'Page Layout Types',
//     },
    // blank: {
    //     id: 'blank',
    //     text: 'Blank',
    //     path: '/page-layouts/blank',
    //     icon: 'check_box_outline_blank ',
    // },
//     pageLayout: {
//         id: 'pageLayout',
//         text: 'Page Layout',
//         path: '/page-layouts',
//         icon: 'BackupTable',
//         subMenu: {
//             headerAndSubheader: {
//                 id: 'headerAndSubheader',
//                 text: 'Header & Subheader',
//                 path: '/page-layouts/header-and-subheader',
//                 icon: 'ViewAgenda',
//             },
//             onlyHeader: {
//                 id: 'onlyHeader',
//                 text: 'Only Header',
//                 path: '/page-layouts/only-header',
//                 icon: 'ViewStream',
//             },
//             onlySubheader: {
//                 id: 'onlySubheader',
//                 text: 'Only Subheader',
//                 path: '/page-layouts/only-subheader',
//                 icon: 'ViewStream',
//             },
//             onlyContent: {
//                 id: 'onlyContent',
//                 text: 'Only Content',
//                 path: '/page-layouts/only-content',
//                 icon: 'WebAsset',
//             },
//         },
//     },
//     asideTypes: {
//         id: 'asideTypes',
//         text: 'Aside Types',
//         path: '/aside-types',
//         icon: 'Vertical Split',
//         subMenu: {
//             defaultAside: {
//                 id: 'defaultAside',
//                 text: 'Default Aside',
//                 path: '/aside-types/default-aside',
//                 icon: 'ViewQuilt',
//             },
//             minimizeAside: {
//                 id: 'minimizeAside',
//                 text: 'Minimize Aside',
//                 path: '/aside-types/minimize-aside',
//                 icon: 'View Compact',
//             },
//         },
//     },
};

export const componentsMenu = {
    // bootstrap: {
    //     id: 'bootstrap',
    //     text: 'Bootstrap',
    //     icon: 'Extension',
    // },
    // notification: {
    //     id: 'notification',
    //     text: 'Notification',
    //     path: '/notifications',
    //     icon: 'NotificationsNone',
    // },
    // hooks: {
    //     id: 'hooks',
    //     text: 'Hooks',
    //     path: '/hooks',
    //     icon: 'Anchor',
    // },
};

// export const productsMenu = {
//     companyA: { id: 'companyA', text: 'Company A', path: '/grid-pages/products', subMenu: null },
//     companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
//     companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
//     companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
// };

export const innerCoursePath = {
    courses: {
        id: 'courses',
        text: 'Courses',
        path: '/courses',
        icon: 'Dashboard',
        subPath: {
            teams: {
                id: 'courseTeam',
                text: 'Team',
                path: '/teams',
                icon: 'ViewArray',
                subPath: {
                    student: {
                        id: 'studentTeam',
                        text: 'Student',
                        path: '/students',
                        icon: 'ViewArray',
                    },
                    teacher: {
                        id: 'teacherTeam',
                        text: 'Teacher',
                        path: '/teachers',
                        icon: 'ViewArray',
                    },
                },
            },
            assignments: {
                id: 'assignments',
                text: 'Assignments',
                path: '/assignments',
                icon: 'ViewArray',
                subPath: {
                    submissions: {
                        path: '/submissions',
                        subPath: {
                            ghs: {
                                path: '/ghs',
                            },
                        },
                    },
                },
            },
            topics: {
                id: 'topics',
                text: 'Topics',
                path: '/topics',
                icon: 'ViewArray',
            },
            lessons: {
                id: 'lessons',
                text: 'Lessons',
                path: '/lessons',
                icon: 'ViewArray',
                subPath: {
                    attachments: {
                        id: 'lessonAttachments',
                        text: 'Lesson Attachments',
                        path: '/attachments',
                        icon: 'ViewArray',
                    },
                },
            },
            // pricePlans: {
            //     id: 'pricingPlans',
            //     text: 'Pricing Plans',
            //     path: '/pricing-plans',
            //     icon: 'ViewArray',
            // },
        },
    },
};

export const innerProfilePath = {
    profile: {
        id: 'profile',
        text: 'Profile',
        path: '/my-profile',
        icon: 'Dashboard',
    },
};

export const healthPath = {
    health: {
        id: 'health',
        text: 'Health',
        path: '/health',
        icon: 'Health',
    },
};
