import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { useHistory } from 'react-router-dom';
import Modal, { ModalBody, ModalHeader } from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import VersionLottie from '../assets/lottie/version.json';

const PremiumUserModal = ({ setIsOpen, isOpen, t }) => {
    const history = useHistory();
    return (
        <Modal
            id='version-modal'
            isAnimation
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            size='lg'
            isUseEscapeClose
            titleId='version-title'
            isStaticBackdrop>
            <ModalHeader />
            <ModalBody>
                <Player
                    autoplay
                    loop
                    src={VersionLottie}
                    style={{ height: '200px', width: '200px', maxWidth: '100%' }}
                />
                <div className='text-center'>
                    <h5 className='text-info'>{t('Your Studio. subscription was approved')}</h5>
                    {/* <h4 className='text-secondary'>{versionInfo?.client?.version || ''}</h4> */}
                    <div className='d-flex justify-content-center'>
                        <div className='p-5'>
                            <Button color='dark' icon='HddFill' onClick={() => history.go(0)}>
                                {t('Explore')}
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

PremiumUserModal.defaultProps = {};

PremiumUserModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    versionInfo: PropTypes.shape({
        client: PropTypes.shape({
            version: PropTypes.string,
            desc: PropTypes.arrayOf(PropTypes.string),
        }),
    }).isRequired,
};

export default PremiumUserModal;
