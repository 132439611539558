import diacriticsMap from './diacriticsMap';
import showNotification from './components/extras/showNotification';
import { demoPages } from './menu';

export function setTokenToLocalStorage(key, value) {
    return Promise.resolve().then(() => {
        localStorage.setItem(key, value);
    });
}

export function getTokenFromLocalStorage(key) {
    return localStorage.getItem(key);
}

// min and max included
export function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const setPrefixUrl = (url) => {
    // FIXME
    if (url && url[0] === '/') {
        return `http:${url}`;
    }
    return url;
};

export const getDriveFileThumbnail = (id, size) => {
    const link = `https://drive.google.com/thumbnail?id=${id}&sz=w${size}`;
    return link;
};

export const getDisplayableDueDate = (dueDate, dueTime, title) => {
    let displayableDueDate = title;
    let hasDate = false;
    let hasTime = false;
    if (
        dueDate?.day !== undefined &&
        dueDate?.day !== null &&
        dueDate?.month !== undefined &&
        dueDate?.month !== null &&
        dueDate?.year !== undefined &&
        dueDate?.year !== null
    ) {
        hasDate = true;
    }

    if (
        dueTime?.hours !== undefined &&
        dueTime?.hours !== null &&
        dueTime?.minutes !== undefined &&
        dueTime?.minutes !== null
    ) {
        hasTime = true;
    }

    try {
        if (hasDate && hasTime) {
            displayableDueDate = `${dueDate?.day}-${dueDate?.month}-${dueDate?.year} ${dueTime?.hours}:${dueTime?.minutes}`;
        } else if (hasDate && !hasTime) {
            displayableDueDate = `${dueDate?.day}-${dueDate?.month}-${dueDate?.year}`;
        }
    } catch (e) {
        return displayableDueDate;
    }
    return displayableDueDate;
};

export const getFullDueDate = (data) => {
    let fullDueDate = new Date();
    try {
        const { year, month, day } = data.dueDate;
        const { hours, minutes } = data.dueTime;
        fullDueDate = new Date(Date.UTC(year, month, day, hours, minutes));
        return fullDueDate;
    } catch (e) {
        return fullDueDate;
    }
};

export const getCurrentDueTime = (fullDueDate, data) => {
    try {
        let currentDueTime = data?.dueTime;
        if (
            currentDueTime?.hours !== undefined &&
            currentDueTime?.hours !== null &&
            currentDueTime?.minutes !== undefined &&
            currentDueTime?.minutes !== null &&
            typeof currentDueTime?.hours === 'number' &&
            typeof currentDueTime?.minutes === 'number'
        ) {
            currentDueTime = {
                hours: fullDueDate.getHours(),
                minutes: fullDueDate.getMinutes(),
            };
        }
        return currentDueTime;
    } catch (e) {
        return data?.dueTime;
    }
};

export const getCurrentDueDate = (fullDueDate, data) => {
    try {
        let currentDueDate = data?.dueDate;
        if (
            currentDueDate?.year !== undefined &&
            currentDueDate?.year !== null &&
            currentDueDate?.month !== undefined &&
            currentDueDate?.month !== null &&
            currentDueDate?.day !== undefined &&
            currentDueDate?.day !== null &&
            typeof currentDueDate?.year === 'number' &&
            typeof currentDueDate?.month === 'number' &&
            typeof currentDueDate?.day === 'number'
        ) {
            currentDueDate = {
                day: fullDueDate.getDate(),
                month: fullDueDate.getMonth() + 1,
                year: fullDueDate.getFullYear(),
            };
        }
        return currentDueDate;
    } catch (e) {
        return data?.dueDate;
    }
};

export const getDueDateAndDueTimeFromFullDueDate = (fullDueDate) => {
    try {
        let fullDueDateToParse = fullDueDate;
        if (typeof fullDueDate === 'string') {
            fullDueDateToParse = new Date(fullDueDate);
        }

        const dueDate = {
            day: fullDueDateToParse.getDate(),
            month: fullDueDateToParse.getMonth() + 1,
            year: fullDueDateToParse.getFullYear(),
        };
        const dueTime = {
            hours: fullDueDateToParse.getHours(),
            minutes: fullDueDateToParse.getMinutes(),
        };
        return {
            dueDate,
            dueTime,
        };
    } catch (e) {
        return {
            dueDate: undefined,
            dueTime: undefined,
        };
    }
};

export const convertIsoToDateTime = (isoTime, t) => {
    try {
        if (!isoTime) {
            return '';
        }

        const convertedDate = new Date(isoTime);
        const day = convertedDate.getDate();
        const month = convertedDate.getMonth() + 1;
        const year = convertedDate.getFullYear();
        const hours = convertedDate.getHours();
        const minutes = convertedDate.getMinutes();

        return `${hours}:${minutes} ${day}/${month}/${year}`;
    } catch (e) {
        return t('No time');
    }
};

export const convertMstoStrs = (time) => {
    try {
        return `${Math.ceil(time / 1000)}s`;
    } catch (e) {
        return '0s';
    }
};

export const replaceSpaceByHypen = (s) => {
    try {
        s = s.trim();
        const stack = [];
        for (let i = 0; i < s.length; i += 1) {
            const letter = s[i];

            if (letter === ' ') {
                if (stack.length > 0) {
                    if (stack[stack.length - 1] !== '-') {
                        stack.push('-');
                    }
                }
            } else {
                // is alphabet
                // eslint-disable-next-line no-lonely-if
                if (diacriticsMap[letter] !== undefined) {
                    // Remove accents diacritics.
                    stack.push(diacriticsMap[letter]);
                } else {
                    // Keep other letters
                    stack.push(letter);
                }
            }
        }
        return stack.join('');
    } catch (e) {
        return s;
    }
};

export const downloadFile = (blob, fileName) => {
    if (navigator.msSaveBlob) {
        // In case of IE 10+
        navigator.msSaveBlob(blob, `${fileName}`);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${fileName}`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const randomArray = (arr) => {
    try {
        let items = [...arr];
        const newItems = [];
        while (items.length > 0) {
            const idx = Math.floor(Math.random() * items.length);
            newItems.push(items[idx]);
            // Remove item
            items = [...items.slice(0, idx), ...items.slice(idx+1)];
        }
        return newItems;
    } catch (e) {
        return arr;
    }
};

export const mappingMimeTypeToIcon = (mimeType) => {
    const defaultIcon = 'AttachFile';
    const mappingTypeToIcon = {
        'image/jpeg': 'Image',
        'image/jpg': 'Image',
        'image/png': 'Image',
    };

    const icon = mappingTypeToIcon?.[mimeType] || defaultIcon;
    return icon;
};

export const postErrorCheckResponse = (axioErr, t) => {
    try {
        if (axioErr?.response?.status === 429) {
            showNotification('429', t('Limit exceeded'));
        } else if (axioErr?.response?.status === 401) {
            const redirectUri = window?.location?.pathname + window?.location?.search;
            const loginUrl = `${window.location.protocol}//${window.location.host}${demoPages.login.path}`;
            setTimeout(() => {
                window.location.replace(
                    `${loginUrl}?redirect_to=${encodeURIComponent(redirectUri)}`,
                );
            }, 2000);
            showNotification('401', t('Token is expired'));
        }
    } catch (e) {
        // FIXME
    }
};
