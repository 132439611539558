import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import SummaryHeader from '../../pages/common/Headers/SummaryHeader';
import DashboardHeader from '../../pages/common/Headers/DashboardHeader';
import ComponentsHeader from '../../pages/common/Headers/ComponentsHeader';
import FormHeader from '../../pages/common/Headers/FormHeader';
import ChartsHeader from '../../pages/common/Headers/ChartsHeader';
import ContentHeader from '../../pages/common/Headers/ContentHeader';
import UtilitiesHeader from '../../pages/common/Headers/UtilitiesHeader';
import IconHeader from '../../pages/common/Headers/IconHeader';
import ExtrasHeader from '../../pages/common/Headers/ExtrasHeader';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import DefaultHeader from '../../pages/common/Headers/DefaultHeader';
import ProfilePageHeader from '../../pages/common/Headers/ProfilePageHeader';
import ProductsHeader from '../../pages/common/Headers/ProductsHeader';
import ProductListHeader from '../../pages/common/Headers/ProductListHeader';
import DashboardBookingHeader from '../../pages/common/Headers/DashboardBookingHeader';
import {
    componentsMenu,
    dashboardMenu,
    demoPages,
    layoutMenu,
    innerCoursePath,
    materialsMenu,
    mleMenu,
    featuresDemos,
    smartEditorHR,
    smartEditor,
    smartPost,
    contentMenu,
    smartBlog,
    adminMenu,
    subscriptionMenu,
} from '../../menu';

const HeaderRoutes = () => {
    const location = useLocation();

    //	Add paths to the array that you don't want to be "Header".
    const withoutHeaderPages = [
        // layoutMenu.pageLayout.subMenu.onlySubheader.path,
        // layoutMenu.pageLayout.subMenu.onlyContent.path,
        // layoutMenu.blank.path,
        demoPages.login.path,
        // demoPages.signUp.path,
        demoPages.page404.path,
        demoPages.verify.path,
        smartEditorHR.path,
        smartPost.path,
        smartEditor.path,
        contentMenu.list.path,
        smartBlog.path,
        adminMenu.studioManagement.path,
        adminMenu.contentManagement.path,
        subscriptionMenu.subscription.path,
        // demoPages.knowledge.subMenu.grid.path,
        // materialsMenu?.create?.path,
        // innerCoursePath.courses.path,
        // mleMenu.mle.path,
        // dashboardMenu.courses.subMenu.joinRequests,
    ];

    return (
        <Switch location={location}>
            {/* BEGIN :: Custom Headers */}

            <Route path={dashboardMenu.summary.path} exact component={SummaryHeader} />
            {/* 
            <Route
                path={[
                    `${layoutMenu.asideTypes.path}/:path?`,
                    layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
                    layoutMenu.pageLayout.subMenu.onlyHeader.path,
                ]}
                exact
                component={PageLayoutHeader}
            /> */}
            {/* END :: Custom Headers */}

            {/* BEGIN :: Without Header */}
            {withoutHeaderPages.map((path) => (
                <Route key={path} path={path} />
            ))}
            {/* BEGIN :: Without Header */}

            <Route component={DefaultHeader} />
        </Switch>
    );
};

export default HeaderRoutes;
