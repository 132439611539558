import { call, put } from 'redux-saga/effects';

import { attemptLoginApiCall } from '../apiCalls/attemptLoginApiCall';

import { setTokenToLocalStorage } from '../utils';

import { TOKEN_KEY } from '../constant';

import { attemptLoginSuccessfully, attemptLoginFailed } from '../actions/attemptLoginAction';

export function* attemptLoginSaga(action) {
    const user = action.payload;
    if (user.email || user.password || user.email !== '' || user.password !== '') {
        try {
            const res = yield call(attemptLoginApiCall, user);
            if (res.status === 200) {
                yield put(attemptLoginSuccessfully(res.data));
                const { token } = res.data;
                // Set userToken to Local Storage
                yield call(setTokenToLocalStorage, TOKEN_KEY, token);
            } else {
                yield put(attemptLoginFailed());
            }
        } catch (e) {
            // FIX_ME
            const errRes = e.response;
            let errors;
            // Error case for Connection Refuse or other unknow Errors
            if (!errRes) {
                errors = {
                    server: {
                        msg: 'SOME_THING_WENT_WRONG',
                    },
                };
            } else {
                errors =
                    {
                        ...errRes.data.errors,
                        ...{
                            resCode: errRes.data.code,
                        },
                    } || {};
            }
            yield put(attemptLoginFailed(errors));
        }
    } else {
        // FIX_ME
        yield put(attemptLoginFailed());
    }
}
