import React from 'react';
import PropTypes from 'prop-types';
import { Player } from '@lottiefiles/react-lottie-player';
import { useHistory } from 'react-router-dom';
import Modal, { ModalBody, ModalHeader } from '../components/bootstrap/Modal';
import Button from '../components/bootstrap/Button';
import VersionLottie from '../assets/lottie/version.json';

const VersionModal = ({ setIsOpen, isOpen, t, versionInfo }) => {
    const history = useHistory();
    const generateDesc = () => {
        let desc = <></>;
        if (versionInfo?.client?.desc?.length > 0) {
            desc = versionInfo?.client?.desc?.map((d) => {
                return <p style={{ marginBottom: 0 }}>{d || ''}</p>;
            });
        }

        return desc;
    };

    return (
        <Modal
            id='version-modal'
            isAnimation
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            size='lg'
            isUseEscapeClose
            titleId='version-title'
            isStaticBackdrop>
            <ModalHeader />
            <ModalBody>
                <Player
                    autoplay
                    loop
                    src={VersionLottie}
                    style={{ height: '200px', width: '200px', maxWidth: '100%' }}
                />
                <div className='text-center'>
                    <h5 className='text-info'>{t('New version has been released.')}</h5>
                    <h4 className='text-secondary'>{versionInfo?.client?.version || ''}</h4>
                    {generateDesc()}
                    <div className='d-flex justify-content-center'>
                        <div className='p-5'>
                            <Button color='dark' icon='HddFill' onClick={() => history.go(0)}>
                                {t('Install')}
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

VersionModal.defaultProps = {};

VersionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    versionInfo: PropTypes.shape({
        client: PropTypes.shape({
            version: PropTypes.string,
            desc: PropTypes.arrayOf(PropTypes.string),
        }),
    }).isRequired,
};

export default VersionModal;
