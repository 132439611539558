import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { ggAttemptLogin } from '../../../actions/ggAttempLoginAction';
import { attemptLogin } from '../../../actions/attemptLoginAction';
import Store from '../../../store';
import showNotification from '../../../components/extras/showNotification';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import { attempSignUpApiCall } from '../../../apiCalls/attempSignUpApiCall';
import { demoPages, dashboardMenu } from '../../../menu';
import ChangingLanguageHeader from '../../common/Headers/ChangingLanguageHeader';

import {
    SOME_THING_WENT_WRONG,
    PROTONX_AI_TF_BIG_DATA_PAGE,
    readableLoginError,
    ATTEMPT_LOGIN_FAILED,
    EMAIL_IS_IN_WRONG_FORMAT,
    EMAIL_IS_REQUIRED,
    LOGIN,
    SIGNUP,
    ERRORS,
} from '../../../constant';
// import Logo from '../../../components/Logo';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ activeTab, t }) => {
    if (activeTab === SIGNUP) {
        return (
            <>
                <div className='text-center h1 fw-bold mt-5'>{t('Create Account')}</div>
                <div className='text-center h4 text-muted mb-5'>{t('Sign up to get started!')}</div>
            </>
        );
    }
    return (
        <>
            <div className='text-center h1 fw-bold mt-5'>{t('Welcome,')}</div>
            <div className='text-center h4 text-muted mb-5'>{t('Sign in to continue!')}</div>
        </>
    );
};

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const showNoti = (message) => {
    showNotification(
        <span className='d-flex align-items-center'>
            <Avatar
                srcSet={USERS.NOONE.srcSet}
                src={USERS.NOONE.src}
                size={36}
                color={USERS.NOONE.color}
                className='me-3'
            />
            <span>{USERS.NOONE.name} sent a message.</span>
        </span>,
        <div>
            <p>{message}</p>
        </div>,
    );
};

const Login = ({ isSignUp }) => {
    const history = useHistory();
    const { t } = useTranslation(['translation', 'menu']);
    const user = useSelector((state) => state.user);
    const [activeTab, setActiveTab] = useState(LOGIN);
    const [signUpSucessfully, setSignUpSucessfully] = useState(false);
    const [signInMessage, setSignInMessage] = useState('');
    const query = useQuery();
    const emailEl = useRef(null);
    const firstNameEl = useRef(null);
    const lastNameEl = useRef(null);
    const passwordEl = useRef(null);
    const passwordConfirmationEl = useRef(null);
    const usernameLoginEl = useRef(null);
    const passwordLoginEl = useRef(null);
    /** Google Login  */
    const successGGResponse = (response) => {
        const tokenId = response.tokenId || null;
        if (tokenId) {
            // Dispatch Action
            Store.dispatch(ggAttemptLogin(tokenId));
        } else {
            showNoti(SOME_THING_WENT_WRONG);
        }
    };

    const loginContent = t('Login with Google');
    const errorGGResponse = () => {
        // showNotification(
        //     <Icon icon='Error' className='h1' />,
        //     <div className='row d-flex align-items-center'>
        //         <div className='col-auto h5'> {t(readableLoginError[ATTEMPT_LOGIN_FAILED])} </div>
        //     </div>,
        // );
    };
    /** End Google Login  */
    /** Listen To Reducer  */
    // Redirect to where we came from
    useEffect(() => {
        if (
            user !== null &&
            user !== undefined &&
            user?.email !== null &&
            user?.email !== undefined
        ) {
            const rediectToUri = query.get('redirect_to');
            if (rediectToUri && rediectToUri.length > 0 && rediectToUri !== demoPages.login.path) {
                history.push(rediectToUri);
            } else {
                history.push(`${dashboardMenu?.summary?.path}`);
            }
        }
    }, [user, history]);
    // If failed to login. Show Notification
    useEffect(() => {
        if (
            user?.errors !== null &&
            user?.errors !== undefined &&
            Object.keys(user.errors).includes('resCode')
        ) {
            const code = user?.errors?.resCode;
            if (code === 400) {
                // showNotification('400', t(ERRORS?.email?.EMAIL_IS_NOT_CONFIRMED));
                if (
                    user?.errors?.password?.msg &&
                    user?.errors?.password?.msg === 'PASSWORD_MUST_CONTAIN_ONE_SPECIAL_CHARACTER'
                ) {
                    setSignInMessage('Password must contain number and special case character');
                } else {
                    setSignInMessage('Please check your email to activate your account.');
                }
            } else if (code === 404) {
                if (
                    user?.errors?.email?.msg &&
                    user?.errors?.email?.msg === 'USER_DOES_NOT_EXIST'
                ) {
                    showNotification('404', t(ERRORS?.email?.USER_DOES_NOT_EXIST));
                    setActiveTab(SIGNUP);
                }
            } else if (code === 401) {
                // showNotification('401', t(ERRORS?.password?.WRONG_PASSWORD));
                setSignInMessage('Wrong password. Please try again. Or sign in by Google');
            }
        }
    }, [user, history]);

    const email = query.get('email');
    const tab = query.get('activeTab');

    // Formik
    let initialValues = {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
        usernameLogin: '',
        passwordLogin: '',
    };

    if (activeTab === LOGIN && email) {
        initialValues = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            passwordConfirmation: '',
            usernameLogin: email || '',
            passwordLogin: '',
        };
    }

    useEffect(() => {
        if (activeTab === LOGIN) {
            usernameLoginEl.current.value = email || '';
            passwordLoginEl.current.value = '';
            setSignUpSucessfully(false);
            setSignInMessage('');
        } else if (
            activeTab === SIGNUP &&
            user?.errors?.resCode === 404 &&
            formikLogin?.values?.usernameLogin &&
            formikLogin?.values?.passwordLogin
        ) {
            emailEl.current.value = formikLogin?.values?.usernameLogin || '';
            passwordEl.current.value = formikLogin?.values?.passwordLogin || '';
            firstNameEl.current.value = '';
            formikSignup?.setValues({
                ...formikSignup.values,
                ...{
                    email: emailEl?.current?.value || '',
                    password: passwordEl?.current?.value || '',
                },
            });
            setSignUpSucessfully(false);
            setSignInMessage('');
        } else if (activeTab === SIGNUP) {
            emailEl.current.value = '';
            firstNameEl.current.value = '';
            setSignUpSucessfully(false);
            setSignInMessage('');
        }
        return () => {
            formikSignup.resetForm();
            formikLogin.resetForm();
        };
    }, [activeTab, formikLogin, formikSignup, query]);

    const formikLogin = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues,
        validationSchema: Yup.object({
            usernameLogin: Yup.string().required(t('Username or email is required')),
            passwordLogin: Yup.string()
                .required(t('No password provided'))
                .min(8, t('Password is too short - should be 8 chars minimum.')),
        }),
        onSubmit: (values, { setErrors }) => {
            if (activeTab === LOGIN) {
                setSignInMessage('');
                const user = {
                    email: values?.usernameLogin,
                    password: values?.passwordLogin,
                };
                Store.dispatch(attemptLogin(user));
            }
        },
    });

    const formikSignup = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues,
        validationSchema: Yup.object({
            email: Yup.string().email(t(EMAIL_IS_IN_WRONG_FORMAT)).required(t(EMAIL_IS_REQUIRED)),
            firstName: Yup.string().required(t('First name is required')),
            lastName: Yup.string().required(t('Last name is required')),
            password: Yup.string()
                .required(t('No password provided'))
                .min(8, t('Password is too short - should be 8 chars minimum.')),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], t('Password must match'))
                .required(t('No password provided')),
        }),
        onSubmit: (values, { setErrors }) => {
            if (activeTab === SIGNUP) {
                attempSignUpApiCall({
                    email: values?.email,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    password: values?.password,
                })
                    .then((res) => {
                        if (res?.status === 200) {
                            setSignUpSucessfully(true);
                            showNotification(t('Successful'), t('Sign up successfully'));
                        } else {
                            // FIXME
                        }
                    })
                    .catch((e) => {
                        const failedRes = e?.response;
                        if (failedRes?.status === 409) {
                            showNotification('409', t(ERRORS?.email?.USER_EXISTS_ALREADY));
                            setActiveTab(LOGIN);
                        } else {
                            showNotification(t('Failed'), t('Failed to sign up'));
                        }
                    });
            }
        },
    });

    return (
        <PageWrapper title='Login' className='bg-warning'>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-dark'>
                            <CardHeader className='d-flex justify-content-end'>
                                <div>
                                    <ChangingLanguageHeader />
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='text-center  my-2'>
                                    <img
                                        src='https://storage.googleapis.com/protonx-mle-production/studio-black.svg'
                                        alt='Studio.'
                                        width='210'
                                        height='210'
                                    />
                                </div>
                                <div className='bg-l10-dark rounded-3'>
                                    <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                                        <div className='col'>
                                            <Button
                                                color='dark'
                                                isLight={activeTab === SIGNUP}
                                                className='rounded-1 w-100'
                                                size='lg'
                                                onClick={() => {
                                                    setActiveTab(LOGIN);
                                                }}>
                                                {t('Login')}
                                            </Button>
                                        </div>
                                        <div className='col '>
                                            <Button
                                                color='dark'
                                                isLight={activeTab === LOGIN}
                                                className='rounded-1 w-100'
                                                size='lg'
                                                onClick={() => {
                                                    setActiveTab(SIGNUP);
                                                }}>
                                                {t('Sign Up')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <LoginHeader t={t} activeTab={activeTab} />
                                <form className='row g-4'>
                                    {activeTab === SIGNUP ? (
                                        <div>
                                            <FormGroup
                                                id='email'
                                                label={t('Email')}
                                                className='col-md-12'>
                                                <Input
                                                    ref={emailEl}
                                                    onBlur={formikSignup?.handleBlur}
                                                    onChange={formikSignup?.handleChange}
                                                    isTouched={formikSignup?.touched?.email}
                                                    invalidFeedback={formikSignup?.errors?.email}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                id='firstName'
                                                label={t('First name')}
                                                className='col-md-12'>
                                                <Input
                                                    ref={firstNameEl}
                                                    onBlur={formikSignup?.handleBlur}
                                                    onChange={formikSignup?.handleChange}
                                                    isTouched={formikSignup?.touched?.firstName}
                                                    invalidFeedback={
                                                        formikSignup?.errors?.firstName
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                id='lastName'
                                                label={t('Last name')}
                                                className='col-md-12'>
                                                <Input
                                                    ref={lastNameEl}
                                                    onBlur={formikSignup?.handleBlur}
                                                    onChange={formikSignup?.handleChange}
                                                    isTouched={formikSignup?.touched?.lastName}
                                                    invalidFeedback={formikSignup?.errors?.lastName}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                id='password'
                                                label={t('Password')}
                                                className='col-md-12'>
                                                <Input
                                                    type='password'
                                                    autoComplete='password'
                                                    ref={passwordEl}
                                                    onBlur={formikSignup?.handleBlur}
                                                    onChange={formikSignup?.handleChange}
                                                    isTouched={formikSignup?.touched?.password}
                                                    invalidFeedback={formikSignup?.errors?.password}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                id='passwordConfirmation'
                                                label={t('Confirm password')}
                                                className='col-md-12'>
                                                <Input
                                                    type='password'
                                                    autoComplete='password'
                                                    ref={passwordConfirmationEl}
                                                    onChange={formikSignup?.handleChange}
                                                    onBlur={formikSignup?.handleBlur}
                                                    isTouched={
                                                        formikSignup?.touched?.passwordConfirmation
                                                    }
                                                    invalidFeedback={
                                                        formikSignup?.errors?.passwordConfirmation
                                                    }
                                                />
                                            </FormGroup>
                                            <div className='row justify-content-md-center'>
                                                {signUpSucessfully && (
                                                    <div className='text-danger text-center'>
                                                        <span>
                                                            {t(
                                                                'A verification link has been sent. Please check your email to activate your account.',
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                                <div className='col-md-4 mt-2'>
                                                    <Button
                                                        color='info'
                                                        className='w-100 py-3'
                                                        onClick={formikSignup?.handleSubmit}>
                                                        {t('Sign Up')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='usernameLogin'
                                                    label={t('Your email or username')}
                                                    className='col-md-12'>
                                                    <Input
                                                        ref={usernameLoginEl}
                                                        onBlur={formikLogin?.handleBlur}
                                                        onChange={formikLogin?.handleChange}
                                                        isTouched={
                                                            formikLogin?.touched?.usernameLogin
                                                        }
                                                        invalidFeedback={
                                                            formikLogin?.errors?.usernameLogin
                                                        }
                                                    />
                                                </FormGroup>
                                                <FormGroup
                                                    id='passwordLogin'
                                                    label={t('Password')}
                                                    className='col-md-12'>
                                                    <Input
                                                        type='password'
                                                        autoComplete='password'
                                                        ref={passwordLoginEl}
                                                        onBlur={formikLogin?.handleBlur}
                                                        onChange={formikLogin?.handleChange}
                                                        isTouched={
                                                            formikLogin?.touched?.passwordLogin
                                                        }
                                                        invalidFeedback={
                                                            formikLogin?.errors?.passwordLogin
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-12'>
                                                <div className='row justify-content-md-center mb-4'>
                                                    {signInMessage && (
                                                        <div className='text-danger text-center'>
                                                            <span>{t(signInMessage)}</span>
                                                        </div>
                                                    )}
                                                    <div className='col-md-4'>
                                                        <Button
                                                            color='warning'
                                                            className='w-100 py-3'
                                                            onClick={formikLogin?.handleSubmit}>
                                                            {t('Login')}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <GoogleLogin
                                                    clientId={process.env.REACT_APP_CLIENT_ID}
                                                    buttonText='Continue with Google'
                                                    onSuccess={successGGResponse}
                                                    onFailure={errorGGResponse}
                                                    render={(renderProps) => (
                                                        <Button
                                                            onClick={renderProps.onClick}
                                                            isOutline
                                                            color='dark'
                                                            className='w-100 py-3 border-light'
                                                            id='customGGLogin'
                                                            icon='CustomGoogle'>
                                                            {loginContent}
                                                        </Button>
                                                    )}
                                                    className='btn btn-outline-dark w-100 py-3 border-light'
                                                    id='customGGLogin'
                                                    style={{
                                                        display: 'inline-block',
                                                        borderRadius: '40px',
                                                    }}
                                                    color='dark'
                                                    cookiePolicy='single_host_origin'
                                                />
                                                <p className='mt-3 mb-0 text-center'>
                                                    <ButtonGroup>
                                                        <Button
                                                            target='_blank'
                                                            tag='a'
                                                            isLight
                                                            color='info'
                                                            href='https://forms.gle/sserPhmL3jHC3q5v6'>
                                                            {t(
                                                                'You are having a trouble logging in?',
                                                            )}
                                                        </Button>
                                                    </ButtonGroup>
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </CardBody>
                        </Card>
                        <div className='text-center'>
                            <a
                                href={PROTONX_AI_TF_BIG_DATA_PAGE}
                                className='text-decoration-none me-3 link-dark'>
                                {t('Create your own content with our AI technologies.')}
                            </a>
                            <br />
                            <a
                                tag='a'
                                color='info'
                                icon='ArrowForward'
                                isRounded
                                href='/'
                                className='fs-6'>
                                {t('Home')}
                            </a>
                        </div>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};
Login.propTypes = {
    isSignUp: PropTypes.bool,
};
Login.defaultProps = {
    isSignUp: false,
};

// Connect linechartdata as props to compenent
export default connect()(Login);
