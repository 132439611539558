import {
    GG_ATTEMPT_LOGIN,
    GG_ATTEMPT_LOGIN_FAILED,
    GG_ATTEMPT_LOGIN_SUCCESSFULLY,
} from '../constant';

export const ggAttemptLogin = (token) => ({
    type: GG_ATTEMPT_LOGIN,
    payload: token,
});

export const ggAttemptLoginFailed = (errors) => ({
    type: GG_ATTEMPT_LOGIN_FAILED,
    payload: errors,
});

export const ggAttemptLoginSuccessfully = (user) => ({
    type: GG_ATTEMPT_LOGIN_SUCCESSFULLY,
    payload: user,
});
