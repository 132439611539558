import {
    ATTEMPT_REGISTER,
    ATTEMPT_REGISTER_FAILED,
    ATTEMPT_REGISTER_SUCCESSFULLY,
    CONFIRM_REGISTER,
    CONFIRM_REGISTER_FAILED,
    CONFIRM_REGISTER_SUCCESSFULLY,
} from '../constant';

export const attemptRegister = (user) => ({
    type: ATTEMPT_REGISTER,
    payload: user,
});

export const attemptRegisterFailed = (error) => ({
    type: ATTEMPT_REGISTER_FAILED,
    payload: error,
});

export const attemptRegisterSuccessfully = (user) => ({
    type: ATTEMPT_REGISTER_SUCCESSFULLY,
    payload: user,
});

// Confirm Register
export const confirmRegister = (user) => ({
    type: CONFIRM_REGISTER,
    payload: user,
});

export const confirmRegisterFailed = (error) => ({
    type: CONFIRM_REGISTER_FAILED,
    payload: error,
});

export const confirmRegisterSuccessfully = (user) => ({
    type: CONFIRM_REGISTER_SUCCESSFULLY,
    payload: user,
});
